[class^="icon-"],
[class*=" icon-"] {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: "oph", serif !important;
   speak: none;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-border:before {
   content: "\e900";
}
.icon-arrow-left:before {
   content: "\e901";
}
.icon-arrow-left-border:before {
   content: "\e902";
}
.icon-arrow-right:before {
   content: "\e903";
}
.icon-arrow-right-border:before {
   content: "\e904";
}
.icon-arrow-up-border:before {
   content: "\e905";
}
.icon-book:before {
   content: "\e906";
}
.icon-chat:before {
   content: "\e907";
}
.icon-chat-border:before {
   content: "\e908";
}
.icon-clock:before {
   content: "\e909";
}
.icon-close:before {
   content: "\e90a";
}
.icon-close-border:before {
   content: "\e90b";
}
.icon-close-thinner:before {
   content: "\e90c";
}
.icon-credit-card:before {
   content: "\e90d";
}
.icon-document:before {
   content: "\e90e";
}
.icon-documents:before {
   content: "\e90f";
}
.icon-double-doc-border:before {
   content: "\e910";
}
.icon-download-border-fill .path1:before {
   content: "\e911";
   color: rgb(0, 0, 0);
}
.icon-download-border-fill .path2:before {
   content: "\e912";
   margin-left: -1em;
   color: rgb(255, 255, 255);
}
.icon-download-border-fill .path3:before {
   content: "\e913";
   margin-left: -1em;
   color: rgb(255, 255, 255);
}
.icon-download-document:before {
   content: "\e914";
}
.icon-download-info:before {
   content: "\e915";
}
.icon-download-info-border:before {
   content: "\e916";
}
.icon-eye-border:before {
   content: "\e917";
}
.icon-facebook:before {
   content: "\e918";
   color: #f9b233;
}
.icon-filter:before {
   content: "\e919";
}
.icon-home-info:before {
   content: "\e91a";
}
.icon-instagram:before {
   content: "\e91b";
   color: #f9b233;
}
.icon-linkedin:before {
   content: "\e91c";
   color: #f9b233;
}
.icon-marker:before {
   content: "\e91d";
}
.icon-marker-fill:before {
   content: "\e91e";
}
.icon-medal:before {
   content: "\e91f";
}
.icon-medal-info:before {
   content: "\e920";
}
.icon-menu:before {
   content: "\e921";
}
.icon-parametre:before {
   content: "\e922";
}
.icon-pencil:before {
   content: "\e923";
}
.icon-plus-border:before {
   content: "\e924";
}
.icon-refresh:before {
   content: "\e925";
}
.icon-search:before {
   content: "\e926";
}
.icon-search-border:before {
   content: "\e927";
}
.icon-tick:before {
   content: "\e928";
   color: #484848;
}
.icon-tooltip:before {
   content: "\e929";
   color: #6ebce9;
}
.icon-tooltip-fill:before {
   content: "\e92a";
   color: #6ebce9;
}
.icon-user:before {
   content: "\e92b";
}
.icon-user-border:before {
   content: "\e92c";
}
.icon-wayout:before {
   content: "\e92d";
}
.icon-you:before {
   content: "\e92e";
   color: #f9b233;
}
.icon-locked:before {
   content: "\e98f";
}
