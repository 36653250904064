@mixin element($element) {
   &__#{$element} {
      @content;
   }
}

@mixin modifier($modifier) {
   &--#{$modifier} {
      @content;
   }
}
