@import '../variables';
@import '../mixins';

.locataire_reclamations, .agents_reclamations {
  @include respond-below('sm') {
    display: block !important;
  }
  .block_title {
    font-size: 25px;
    font-weight: 500;
    color: $color_brown_dark;
    margin-bottom: 1.5rem;

    @include respond-below('sm') {
      font-size: 15px;
    }
  }
  .table {
    // Mobile
    td {
      .title {
        display: none;
      }
    }
    @include respond-below('sm') {
      display: block;

      thead {
        display: none;
      }

      tbody,
      tr,
      td,
      th {
        display: block;
      }

      tr {
        border-top: 1px solid $color_blue_light;
        padding: 1rem 0;
        &:last-child {
          border-bottom: 1px solid $color_blue_light;
        }
        &:after {
          content: '';
          clear: both;
          display: table;
        }
      }
      td {
        border: none;
        float: left;
        width: 100%;
        padding: 0.25rem;
        .title {
          display: inline-block;
          margin-right: 10px;
        }
        &.location {
          max-width: 40%;
        }
        &.section {
          max-width: 60%;
        }
        &.date,
        &.object {
          font-weight: 500;
          color: $color_blue_light;
        }
        &.action {
          padding-top: 1rem;
        }
        &.complaint-list {
          margin: 0.5rem -0.5rem;
          .complaint-item {
            background-color: $color_gray_light;
            padding: 1rem 0.5rem;
            margin-bottom: 0.5rem;
            p {
              padding: 0.25rem 0;
              &.date {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .btn-detail {
      float: right;

      &.active {
        i {
          transform: rotate(180deg);
        }
      }
      @include respond-below('sm') {
        float: none;
        i {
          transform: rotate(90deg);
        }

        // &:active,
        &.active {
          i {
            transform: rotate(270deg);
          }
        }
      }
    }
  }
  .block_action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
.locataire_reclamations_detail {
  display: none;
  padding: 2rem 0;
  @include respond-below('sm') {
    display: none !important;
  }

  .block_title {
    margin-bottom: 1.5rem;
    span {
      font-size: 25px;
      font-weight: 500;
      color: $color_brown_dark;
      @include respond-below('sm') {
        font-size: 15px;
      }
    }
  }
  .btn-back {
    font-size: 50px;
    margin-right: 1rem;
    vertical-align: middle;
  }

  .description {
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    text-align: justify;
  }

  .libelle_jour {
    font-size: 15px;
    font-weight: 400;
  }
  .libelle_heure {
    font-size: 15px;
    font-weight: 400;
  }
  .contact_jour {
    font-size: 15px;
  }
  .contact_libelle {
    font-size: 15px;
    font-weight: 400;
  }
  .detail_jour {
    font-size: 15px;
  }
  .detail_heure {
    font-size: 15px;
  }

  table.table_events {
    tbody {
      tr {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: $color_gray_light;
        border: 5px solid white;
        &:first-child {
          border-top: 1px solid $color_blue_light;
        }
      }
    }

    th,
    td {
      padding: 1rem;
      &.date {
        width: 15%;
      }
      &.planned_on {
        text-align: right;
      }
    }
  }
}

#new_complaint_dialog {
  // background-color: red;

  .custom-form {
    @include respond-above('md') {
      max-width: 100%;
    }

    .form-upload-single-link__wrapper {
      @include respond-below('sm') {
        width: 100%;
      }
    }
    hr {
      // display: none;
      // @include respond-below('sm') {
      border-top: 1px solid $color_blue_light;
      margin-top: 3rem;
      // }
    }
  }
  .block-attention {
    position: relative;
    background-color: $color_blue_light_02;
    padding: 1.5rem;
    margin-top: 1.5rem;
    @include respond-above('md') {
      margin-top: 0.5rem;
    }

    font-size: 13px;
    b {
      font-weight: 700;
    }
    a {
      font-weight: 300;
      color: #979797;
      i {
        vertical-align: middle;
      }
      // span {
      //   color: #979797;
      // }
    }

    &:after {
      content: '';
      position: absolute;
      transform: translateX(-50%) rotate(45deg);
      left: 50%;
      top: -10px;
      width: 20px;
      height: 20px;
      background: $color_blue_light_02;
      @include respond-above('md') {
        left: 80%;
      }
    }
  }

  .radio-group {
    &.intervention-day {
      margin-top: 15px;
      .bmd-form-group {
        @include respond-below('md') {
          flex: 1 0 33%;
          max-width: 33%;
          margin-right: 0;
        }
      }
    }
    &.intervention-time {
      .bmd-form-group {
        @include respond-below('md') {
          flex: 1 0 50%;
          max-width: 50%;
          margin-right: 0;
        }
      }
    }
  }
}

.custom-pagination {
  #pagination_bar_demande, #pagination_bar_reclamation, #pagination_bar_composition, #pagination_bar_profil, #pagination_bar_assurance {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.paginationjs {
  line-height: 1.6;
  font-family: Marmelad, "Lucida Grande", Arial, "Hiragino Sans GB", Georgia, sans-serif;
  font-size: 14px;
  box-sizing: initial
}

.paginationjs:after {
  display: table;
  content: " ";
  clear: both
}

.paginationjs .paginationjs-pages {
  float: left
}

.paginationjs .paginationjs-pages ul {
  float: left;
  margin: 0;
  padding: 0
}

.paginationjs .paginationjs-go-button, .paginationjs .paginationjs-go-input, .paginationjs .paginationjs-nav {
  float: left;
  margin-left: 10px;
  font-size: 14px
}

.paginationjs .paginationjs-pages li {
  float: left;
  border: 1px solid #aaa;
  border-right: none;
  list-style: none
}

.paginationjs .paginationjs-pages li > a {
  min-width: 30px;
  height: 28px;
  line-height: 28px;
  display: block;
  background: #fff;
  font-size: 14px;
  color: #333;
  text-decoration: none;
  text-align: center
}

.paginationjs .paginationjs-pages li > a:hover {
  background: #f9b233;
  color: #fff;
}

.paginationjs .paginationjs-pages li.active {
  border: none
}

.paginationjs .paginationjs-pages li.active > a {
  height: 30px;
  line-height: 30px;
  background: #f9b233;
  color: #fff
}

.paginationjs .paginationjs-pages li.disabled > a {
  opacity: .3
}

.paginationjs .paginationjs-pages li.disabled > a:hover {
  background: 0 0
}

.paginationjs .paginationjs-pages li:first-child, .paginationjs .paginationjs-pages li:first-child > a {
  border-radius: 3px 0 0 3px
}

.paginationjs .paginationjs-pages li:last-child {
  border-right: 1px solid #aaa;
  border-radius: 0 3px 3px 0
}

.paginationjs .paginationjs-pages li:last-child > a {
  border-radius: 0 3px 3px 0
}

.paginationjs .paginationjs-go-input > input[type=text] {
  width: 30px;
  height: 28px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0;
  font-size: 14px;
  text-align: center;
  vertical-align: baseline;
  outline: 0;
  box-shadow: none;
  box-sizing: initial
}

.paginationjs .paginationjs-go-button > input[type=button] {
  min-width: 40px;
  height: 30px;
  line-height: 28px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #aaa;
  text-align: center;
  padding: 0 8px;
  font-size: 14px;
  outline: 0;
  box-shadow: none;
  color: #333;
  cursor: pointer;
  vertical-align: middle \9
}

.paginationjs.paginationjs-theme-blue .paginationjs-go-input > input[type=text], .paginationjs.paginationjs-theme-blue .paginationjs-pages li {
  border-color: #289de9
}

.paginationjs .paginationjs-go-button > input[type=button]:hover {
  background-color: #f8f8f8
}

.paginationjs .paginationjs-nav {
  height: 30px;
  line-height: 30px
}

.paginationjs .paginationjs-go-button, .paginationjs .paginationjs-go-input {
  margin-left: 5px \9
}

.paginationjs.paginationjs-small {
  font-size: 12px
}

.paginationjs.paginationjs-small .paginationjs-pages li > a {
  min-width: 26px;
  height: 24px;
  line-height: 24px;
  font-size: 12px
}

.paginationjs.paginationjs-small .paginationjs-pages li.active > a {
  height: 26px;
  line-height: 26px
}

.paginationjs.paginationjs-small .paginationjs-go-input {
  font-size: 12px
}

.paginationjs.paginationjs-small .paginationjs-go-input > input[type=text] {
  width: 26px;
  height: 24px;
  font-size: 12px
}

.paginationjs.paginationjs-small .paginationjs-go-button {
  font-size: 12px
}

.paginationjs.paginationjs-small .paginationjs-go-button > input[type=button] {
  min-width: 30px;
  height: 26px;
  line-height: 24px;
  padding: 0 6px;
  font-size: 12px
}

.paginationjs.paginationjs-small .paginationjs-nav {
  height: 26px;
  line-height: 26px;
  font-size: 12px
}

.paginationjs.paginationjs-big {
  font-size: 16px
}

.paginationjs.paginationjs-big .paginationjs-pages li > a {
  min-width: 36px;
  height: 34px;
  line-height: 34px;
  font-size: 16px
}

.paginationjs.paginationjs-big .paginationjs-pages li.active > a {
  height: 36px;
  line-height: 36px
}

.paginationjs.paginationjs-big .paginationjs-go-input {
  font-size: 16px
}

.paginationjs.paginationjs-big .paginationjs-go-input > input[type=text] {
  width: 36px;
  height: 34px;
  font-size: 16px
}

.paginationjs.paginationjs-big .paginationjs-go-button {
  font-size: 16px
}

.paginationjs.paginationjs-big .paginationjs-go-button > input[type=button] {
  min-width: 50px;
  height: 36px;
  line-height: 34px;
  padding: 0 12px;
  font-size: 16px
}

.paginationjs.paginationjs-big .paginationjs-nav {
  height: 36px;
  line-height: 36px;
  font-size: 16px
}

.paginationjs.paginationjs-theme-blue .paginationjs-pages li > a {
  color: #289de9
}

.paginationjs.paginationjs-theme-blue .paginationjs-pages li > a:hover {
  background: #e9f4fc
}

.paginationjs.paginationjs-theme-blue .paginationjs-pages li.active > a {
  background: #289de9;
  color: #fff
}

.paginationjs.paginationjs-theme-blue .paginationjs-pages li.disabled > a:hover {
  background: 0 0
}

.paginationjs.paginationjs-theme-blue .paginationjs-go-button > input[type=button] {
  background: #289de9;
  border-color: #289de9;
  color: #fff
}

.paginationjs.paginationjs-theme-green .paginationjs-go-input > input[type=text], .paginationjs.paginationjs-theme-green .paginationjs-pages li {
  border-color: #449d44
}

.paginationjs.paginationjs-theme-blue .paginationjs-go-button > input[type=button]:hover {
  background-color: #3ca5ea
}

.paginationjs.paginationjs-theme-green .paginationjs-pages li > a {
  color: #449d44
}

.paginationjs.paginationjs-theme-green .paginationjs-pages li > a:hover {
  background: #ebf4eb
}

.paginationjs.paginationjs-theme-green .paginationjs-pages li.active > a {
  background: #449d44;
  color: #fff
}

.paginationjs.paginationjs-theme-green .paginationjs-pages li.disabled > a:hover {
  background: 0 0
}

.paginationjs.paginationjs-theme-green .paginationjs-go-button > input[type=button] {
  background: #449d44;
  border-color: #449d44;
  color: #fff
}

.paginationjs.paginationjs-theme-yellow .paginationjs-go-input > input[type=text], .paginationjs.paginationjs-theme-yellow .paginationjs-pages li {
  border-color: #ec971f
}

.paginationjs.paginationjs-theme-green .paginationjs-go-button > input[type=button]:hover {
  background-color: #55a555
}

.paginationjs.paginationjs-theme-yellow .paginationjs-pages li > a {
  color: #ec971f
}

.paginationjs.paginationjs-theme-yellow .paginationjs-pages li > a:hover {
  background: #fdf5e9
}

.paginationjs.paginationjs-theme-yellow .paginationjs-pages li.active > a {
  background: #ec971f;
  color: #fff
}

.paginationjs.paginationjs-theme-yellow .paginationjs-pages li.disabled > a:hover {
  background: 0 0
}

.paginationjs.paginationjs-theme-yellow .paginationjs-go-button > input[type=button] {
  background: #ec971f;
  border-color: #ec971f;
  color: #fff
}

.paginationjs.paginationjs-theme-red .paginationjs-go-input > input[type=text], .paginationjs.paginationjs-theme-red .paginationjs-pages li {
  border-color: #c9302c
}

.paginationjs.paginationjs-theme-yellow .paginationjs-go-button > input[type=button]:hover {
  background-color: #eea135
}

.paginationjs.paginationjs-theme-red .paginationjs-pages li > a {
  color: #c9302c
}

.paginationjs.paginationjs-theme-red .paginationjs-pages li > a:hover {
  background: #faeaea
}

.paginationjs.paginationjs-theme-red .paginationjs-pages li.active > a {
  background: #c9302c;
  color: #fff
}

.paginationjs.paginationjs-theme-red .paginationjs-pages li.disabled > a:hover {
  background: 0 0
}

.paginationjs.paginationjs-theme-red .paginationjs-go-button > input[type=button] {
  background: #c9302c;
  border-color: #c9302c;
  color: #fff
}

.paginationjs.paginationjs-theme-red .paginationjs-go-button > input[type=button]:hover {
  background-color: #ce4541
}

.paginationjs .paginationjs-pages li.paginationjs-next {
  border-right: 1px solid #aaa \9
}

.paginationjs .paginationjs-go-input > input[type=text] {
  line-height: 28px \9
;
  vertical-align: middle \9
}

.paginationjs.paginationjs-big .paginationjs-pages li > a {
  line-height: 36px \9
}

.paginationjs.paginationjs-big .paginationjs-go-input > input[type=text] {
  height: 36px \9
;
  line-height: 36px \9
}

.locataire-page {
  @media (min-width: 992px) {
    .modal-dialog .modal-content .modal-body .custom-form {
      max-width: 80%;
    }
  }
}

#form_new_complaint {
  .oph-block {
    .error {
      width: 115%;
    }
  }
}

.locataire-reclamation-custom-toast {
  position: fixed;
  top: 30px;
  right: 30px;
  min-width: 150px;

  &.show {
    z-index: 11111;
      &.success {
        background-color: #ADEBAD;
      }
      &.failed {
        background-color: #EBADAD;
      }
    .toast {
      opacity:1;
    }
  }
}