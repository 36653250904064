.btn-submit-payment:disabled:hover {
  cursor: inherit;
  background-color: #f9b233 !important;
  color: inherit !important;
}

/* btn-modifier */
@media screen and (min-width: 1400px) and (max-width: 3000px) {
  .align-assurance {
    top: 18px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px){
  .align-assurance {
    top: 21px;
  }
}@media screen and  (max-width: 1200px){
  .align-assurance {
    top: 35px;
  }
}

.align-btn {
  top: 76%;
  position: absolute;
  width: 80%;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .align-btn {
    top: 77%;
    width: 80%;
    position: absolute;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .align-btn {
    top: 75%;
    width: 80%;
    position: absolute;
  }
}
@media screen and (max-width: 768px) {
  .align-btn {
    top: 65%;
    position: absolute;
  }
}

.btn-modifier-rib {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 2% 5%;
}

