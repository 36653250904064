.section-header {
  background-color: $color_gray_light;
  @include paddingSection();

  .section-title {
    font-size: 20px;
    font-weight: bold;
    color: $color_brown;
    margin-bottom: 0;

    @include respond-above('md') {
      font-size: 30px;
    }

    &.p-xs-15 {
      @include respond-below('xs') {
        padding: 15px;
      }
    }
    &.p-xs-25 {
      @include respond-below('xs') {
        padding: 25px;
      }
    }
  }
  .section-filter {
    margin-top: 1rem;
    @include respond-above('xl') {
      margin-top: 2rem;
    }
  }
}
.section-center {
  padding: 2rem;

  @include respond-above('md') {
    padding: 3rem;
  }
  @include respond-above('lg') {
    padding: 4rem 3rem;
  }

  .section-title {
    font-size: 25px;
    font-weight: 500;
    // line-height: 61px;
    text-align: center;

    color: $color_brown_dark;
    margin-bottom: 3rem;

    @include respond-below('md') {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.3;
    }

    i {
      font-size: 16px;
      margin-left: 0.5rem;
      font-weight: bold;
    }
  }
}
