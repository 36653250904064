.locataire_profil {
  margin-top: 9rem;
  @include respond-below("sm") {
    margin-top: 5rem;
  }
  .profil-block-list {
    margin: 2rem 0 8.5rem;

    @include respond-below("sm") {
      margin: 2rem 0 1rem;
    }
    .block-item {
      background-color: $color-yellow;
      padding: 20px 30px 20px;
      position: relative;
      // min-height: 543px;
      @include respond-below("md") {
        margin-bottom: 6rem;
      }
      //.btn-modifier, .btn-modifier-assurance {
      //  margin-top: 35px;
      //}
      &__title {
        text-align: center;
        margin-top: 4rem;
        @include respond-below("sm") {
          margin-top: 2rem;
        }
        .title-img {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        h3 {
          font-size: 30px;
        }
        @include respond-below("lg") {
          h3 {
            font-size: 18px;
          }
          .title-img {
            width: 123px;
            @include respond-below("sm") {
              width: 80px;
            }
          }
        }
      }
      &__content {
        .group-content {
          width: 100%;
          font-size: 13px;
          padding-top: 7px;
          color: #000;
          @include respond-above("md") {
            display: flex;
            flex-direction: column;
          }
          @include respond-above("lg") {
            flex-direction: row;
            font-size: 15px;
          }
          &.file-upload {
            display: block;
            .file-doc {
              background: $color_white;
              color: $color_yellow;
              padding: 12px 20px;
              display: flex;
              border-radius: 25px;
              align-items: center;
              font-size: 12px;
              width: 170px;
              margin-top: 10px;
              i {
                color: $color_black;
                padding-right: 10px;
              }
            }
          }
          .label {
            font-weight: 500;
            flex: 0 0 45%;
            max-width: 45%;
            @include respond-below("lg") {
              max-width: 100%;
              flex: 0 0 100%;
            }
          }
          .content {
            font-weight: 300;
            flex: 0 0 55%;
            max-width: 55%;
            word-break: break-all;
            @include respond-below("lg") {
              max-width: 100%;
              flex: 0 0 100%;
              .content-item {
                display: inline-block;
                &:nth-child(2) {
                  &::before {
                    content: "/";
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .profil-form {
    //background: url(../../images/profil_form_bg.png) no-repeat 50% 0;
    // padding-top: 80px;
    //background-size: cover;
    position: relative;

    #profil-form {
      transform: translate(0, -5%);
    }
    //@include respond-below("md") {
    //  background: url(../../images/profil_form_bg_mobile.png) no-repeat 50% 0;
    //  background-size: cover;
    //}
    .profil-form-btn {
      margin: 5rem 0 2rem;
    }
    .btn-aid-item,
    .btn-enregistrer {
      margin-bottom: 4rem;
    }

    .note-upload {
      @media (min-width: 1200px) {
        margin-left: 10px;
      }
      color: silver;
      font-size: 14px;
    }

    .form-group-upload__inner {
      margin-bottom: -20px;
      .item {
        margin-bottom: 20px;
      }
    }

    .form-upload-single-link {
      .form-group {
        background: transparent;
      }
    }

    .profil-form-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include respond-below("md") {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        > div {
          order: 2;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .profil-form-img {
          flex: 0 0 100%;
          order: 1;
          max-width: 100%;
          margin-bottom: 25px;
          img {
            width: 220px;
          }
        }
      }
    }

    .info-block {
      border-top: 1px solid $color_blue_light;
      padding: 10px 0;
      //  margin-bottom: 30px;
      .title {
        color: $color_blue_light;
        font-weight: 500;
        font-size: 15px;
        &::after {
          counter-increment: counter;
          content: counter(counter);
        }
      }
      @include respond-below("md") {
        display: flex;
        .info-person-control {
          margin-left: auto;
        }
      }
    }

    .info-person-form-wrap {
      // width: 1000px;
      @media (min-width: 992px) {
        width: 790px;
      }
      @media (min-width: 1200px) {
        width: 950px;
      }
    }

    .custom-form {
      .info-person-form-wrap {
        .show-role {
          margin-top: 10px;
          padding: 11px 15px;
          font-size: 14px;
          font-weight: 300;
          color: #000;
          border-radius: 5px;
          background-color: #f7f7f7;
          border: 1px solid #eee;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .info-person {
        position: relative;
        padding-bottom: 50px;
        margin-top: 0;
        &__wrapper {
          counter-reset: counter;
        }
        + .info-person {
          margin-top: 0;
        }

        .nom_de_jeune_fille-area,
        .upload-certificat,
        .upload-releve-information,
        .upload-releve,
        .upload-school-certificate {
          display: none;
        }
        &-control {
          @media (min-width: 1000px) {
            position: absolute;
            top: 50px;
            right: 0;
          }
          @media (min-width: 1200px) {
            top: 45px;
          }
          .icon {
            cursor: pointer;
            font-size: 2rem;
            @media (min-width: 768px) {
              font-size: 50px;
            }
          }
          .remove-person {
            color: $color_yellow;
          }

          .collapse-block + .remove-person {
            margin-left: 15px;
            @media (max-width: 1199px) {
              margin-left: 10px;
            }
            @media (max-width: 414px) {
              margin-left: 5px;
            }
          }
        }
        .collapse-block {
          transition: 300ms ease-in-out;
          &.open {
            transform: rotate(180deg);
          }
        }
        .collapse-block-translate {
          display: none;
          &.open {
            display: block;
          }
        }
      }
    }
  }
  .remove-person {
    display: none;
    &.show {
      display: inline-block;
      margin-top: 2rem;
    }
  }
  .remove-person-request-pending {
    display: none;
    &.show {
      display: inline-block;
      margin-top: 2rem;
    }
  }
  .select-role {
    label {
      font-size: 10px !important;
      top: 20% !important;
    }
    .select2-selection__rendered {
      opacity: 1 !important;
    }
    .select2-selection__arrow {
      display: none !important;
    }
  }
}

.update-coordinate-profil-request-pending, .update-assurance-request-pending {
  opacity: 0;
  display: block;
  text-align: center;
  position: relative;
  margin: 10px;
  &.show {
    opacity: 1;
  }
}

.btn-setting {
  @include respond-below("sm") {
    position: absolute;
    right: 5%;
    top: 160px;
  }

  @include respond-below("xxs") {
    font-size: 25px;
    top: 150px;
  }
}

@keyframes typing {
  0% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 1);
  }
}

.custom-two-field {
  &.custom-form .form-group.bmd-form-group {
    background: #fff;
    border: 1px solid transparent;
  }
}


.modify_composition,
.remove-person-pop-up,
.add-person-pop-up{

  .modal-dialog .modal-content .modal-body h3,
  .modal-dialog .modal-content .modal-body h4{
    color: #f9b233;
  }
  .custom-form {
  }
  .locataire-dn-message {
    color: red;
    line-height: 1.3;
    font-size: 13px;
    margin-bottom: 0;
    position: relative;
    top: -7px;
  }
}

.btn-modifier-coordinate, .btn-modifier-assurance {
  position: relative;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.locataire_profil {
  .profil-block-list {
    .assurance-upload {
      &.hide {
        opacity: 0;
        pointer-events: none;
      }
    }
    .text--light {
      min-height: 70px;
      @include respond-below("sm") {
        min-height: 0;
      }
    }
  }

  .select2-container--default.select2-container--disabled .select2-selection--single,
  .custom-file-control:disabled,
  .custom-file-control[readonly],
  .form-control:disabled, .form-control[readonly] {
    background-color: transparent;
  }
}

.onglet-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}