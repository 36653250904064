.block-content-tab {
  margin-bottom: 3rem;
  .title {
    font-size: 25px;
    font-weight: 500;
    color: $color_brown_dark;
    // padding-top: 2rem;
  }
}

.locataire_factures {
  .block-content-tab {
    .title {
      margin-bottom: 50px;
      @include respond-below('md') {
        margin-bottom: 20px;
      }
      .amount_total {
        font-size: 25px;
        color: $color_blue_light;
        font-weight: 300;
        @include respond-below('md') {
          font-size: 15px;
          margin-left: unset;
          order: 1;
          font-weight: 500;
          width: 100%;
        }
      }
      > p {
        font-weight: 500;
        color: $color_brown_dark;
        @include respond-below('md') {
          order: 2;
        }
      }
      @include respond-below('md') {
        flex-wrap: wrap;
        font-size: 15px;
      }
    }
  }
  .custom-form {
    .radio-group {
      .bmd-radio {
        &::before,
        &::after {
          border-radius: 0;
        }
      }
    }
  }
  .action {
    .btn {
      padding: 0;
      float: right;
      i {
        font-size: 38px;
        color: $color_yellow;
        @include respond-below('md') {
          &::before {
            content: '\e917';
          }
        }
      }
      &:hover,
      &:focus,
      &:active {
        // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        background-color: transparent;
      }
      -webkit-tap-highlight-color: transparent;
    }
  }

  table {
    &.locataire-facture-tale {
      .libelle {
        width: 40%;
      }

      @include respond-below('md') {
        .libelle,
        .montant {
          display: none;
        }
        thead th {
          white-space: nowrap;
        }
      }

      td {
        border-top: unset;
      }
    }
  }
  .btn-payer {
    margin: 40px 0;
    @include respond-below('md') {
      margin: 25px 0 30px;
    }
  }
  .block-contact {
    background-color: $color_gray_light;
    color: $color_black;
    padding: 45px 100px;
    @include respond-below('md') {
      padding: 15px;
    }
    .contact-btn {
      color: $color_yellow;
      font-weight: 500;
    }
  }
}
#locataire_facture.modal {
  #montant {
    font-size: 24px;
    padding-bottom: 30px;
    text-align: right;
  }
}
