.text {
   @include modifier("light") {
      font-weight: 300;
   }
   @include modifier("medium") {
      font-weight: 500;
   }
   @include modifier("bold") {
      font-weight: 700;
   }
   @include modifier("fs-8") {
      font-size: 9px;
   }
   @include modifier("fs-9") {
      font-size: 9px;
   }
   @include modifier("fs-10") {
      font-size: 10px;
   }
   @include modifier("fs-11") {
      font-size: 11px;
   }
   @include modifier("fs-12") {
      font-size: 12px;
   }
   @include modifier("fs-13") {
      font-size: 13px;
   }
   @include modifier("fs-14") {
      font-size: 14px;
   }
   @include modifier("fs-15") {
      font-size: 15px;
   }
   @include modifier("fs-16") {
      font-size: 16px;
   }
   @include modifier("fs-18") {
      font-size: 18px;
   }
   @include modifier("fs-20") {
      font-size: 20px;
   }
   @include modifier("fs-25") {
      font-size: 25px;
   }
   @include modifier("fs-30") {
      font-size: 30px;
   }
   @include modifier("up-fs-11") {
      @media (min-width: 1025px) {
         font-size: 11px;
      }
   }
   @include modifier("up-fs-12") {
      @media (min-width: 1025px) {
         font-size: 12px;
      }
   }
   @include modifier("up-fs-13") {
      @media (min-width: 1025px) {
         font-size: 13px;
      }
   }
   @include modifier("up-fs-14") {
      @media (min-width: 1025px) {
         font-size: 14px;
      }
   }
   @include modifier("up-fs-15") {
      @media (min-width: 1025px) {
         font-size: 15px !important;
      }
   }
   @include modifier("up-fs-16") {
      @media (min-width: 1025px) {
         font-size: 16px;
      }
   }
   @include modifier("up-fs-17") {
      @media (min-width: 1025px) {
         font-size: 17px;
      }
   }
   @include modifier("up-fs-18") {
      @media (min-width: 1025px) {
         font-size: 18px;
      }
   }
   @include modifier("up-fs-19") {
      @media (min-width: 1025px) {
         font-size: 19px;
      }
   }
   @include modifier("up-fs-20") {
      @media (min-width: 1025px) {
         font-size: 20px;
      }
   }
   @include modifier("up-fs-25") {
      @media (min-width: 1025px) {
         font-size: 25px;
      }
   }
   @include modifier("up-fs-30") {
      @media (min-width: 1025px) {
         font-size: 30px;
      }
   }
   @include modifier("down-fs-11") {
      @media (max-width: 991px) {
         font-size: 11px;
      }
   }
   @include modifier("down-fs-12") {
      @media (max-width: 991px) {
         font-size: 12px;
      }
   }
   @include modifier("down-fs-13") {
      @media (max-width: 991px) {
         font-size: 13px;
      }
   }
   @include modifier("down-fs-14") {
      @media (max-width: 991px) {
         font-size: 14px;
      }
   }
   @include modifier("down-fs-15") {
      @media (max-width: 991px) {
         font-size: 15px;
      }
   }
   @include modifier("down-fs-16") {
      @media (max-width: 991px) {
         font-size: 16px;
      }
   }
   @include modifier("down-fs-17") {
      @media (max-width: 991px) {
         font-size: 17px;
      }
   }
   @include modifier("down-fs-18") {
      @media (max-width: 991px) {
         font-size: 18px;
      }
   }
   @include modifier("down-fs-19") {
      @media (max-width: 991px) {
         font-size: 19px;
      }
   }
   @include modifier("down-fs-20") {
      @media (max-width: 991px) {
         font-size: 20px !important;
      }
   }
   @include modifier("down-fs-22") {
      @media (max-width: 991px) {
         font-size: 22px !important;
      }
   }
   @include modifier("down-fs-25") {
      @media (max-width: 991px) {
         font-size: 25px;
      }
   }
}
