@import '../variables';
@import '../mixins';

.timeline {
   margin: 96px 0;
   background: #ffffff;
   @media (max-width: 991px) {
      margin: 0;
   }
   .btn-loadmore {
      margin-top: -5%;
   }
}
.oph-timeline {
   position: relative;
   overflow: hidden;
   color: #000;
   .timeline-before {
      content: "";
      display: block;
      position: absolute;
      height: 4000px;
      width: 2px;
      background: rgba(110, 188, 233, 0.14);
      left: 50%;
      transform: translateX(-50%);
      margin-top: 35px;
      @media (max-width: 991px) {
         top: 61px;
      }
   }

   .item {
      position: relative;
      margin-bottom: 40px;
      &.is-hidden {
         opacity: 0 !important;
         transition-property: opacity, transform, -webkit-transform;
         margin: 0 !important;
         height: 0;
      }
      &.fade-in {
         opacity: 1;
         transform: translateZ(0);
         height: auto;
      }

      &:nth-child(3) {
         @media (min-width: 992px) {
            margin-top: 120px;
         }
      }

      &:not(:first-child) {
         @media (max-width: 991px) {
            margin-top: 25px;
         }
      }
      &:nth-child(2) {
         @media (max-width: 992px) {
            margin-top: 50px;
         }
         @media (max-width: 767px) {
            margin-top: 30px;
         }
      }

      @media (max-width: 991px) {
         margin-bottom: 25px;
         &:first-child {
            margin-top: 50px;
         }
      }

      @media (min-width: 992px) {
         width: 50%;
         float: left;
         clear: left;
      }
      &:before,
      &:after {
         content: " ";
         display: table;
      }
      &:after {
         clear: both;
      }
      &.left {
         &.is-show {
            margin-bottom: 30px;
         }
         &:first-child {
            @media (min-width: 992px) {
               margin-bottom: 50px;
            }
         }
         &:last-child {
            margin-bottom: 0;
         }
         .oph-timeline__item {
            @media (min-width: 992px) {
               float: left;
            }
         }
         .oph-timeline__year {
            @media (min-width: 992px) {
               right: -110px;
            }
         }
         &.aos-init.aos-animate {
            &:last-child {
               margin-bottom: 0;
            }
         }
      }
      &.right {
         &:first-child {
            @media (min-width: 992px) {
               float: right;
               clear: right;
               margin-top: 100px;
            }
         }
         @media (min-width: 992px) {
            float: right;
            clear: right;
            &.is-show {
               margin-bottom: 30px;
            }
         }
         .oph-timeline__item {
            @media (min-width: 992px) {
               float: right;
               text-align: right;
            }
            @media (max-width: 991px) {
               text-align: left;
            }
         }
         .oph-timeline__year {
            @media (min-width: 992px) {
               left: -110px;
            }
         }
      }
   }
   @include element("right") {
      @include element("title") {
         @media (max-width: 991px) {
            padding-left: 25px;
            padding-top: 25px;
         }
      }
   }
   @include element("year") {
      padding: 10px 12px;
      text-align: center;
      background-color: #fff;
      &__last-year {
         cursor: pointer;
      }
      &.above-loadmore {
         margin-bottom: 15px;
         color: $color-gray;
         margin-left: auto;
         margin-right: auto;
      }
      &.under-loadmore {
         margin-top: 30px;
         color: #000;
         margin-left: auto;
         margin-right: auto;
         // &:before {
         //    content: "";
         //    width: 2px;
         //    background: rgba(110, 188, 233, 0.14);
         //    height: 35px;
         //    position: absolute;
         //    top: -30px;
         // }
      }
      &.is-hidden {
         opacity: 0;
         transition-property: opacity, transform, -webkit-transform;
      }
      &.fade-in {
         opacity: 1;
         transform: translateZ(0);
      }
      @media (min-width: 992px) {
         min-width: 65px;
         top: 35px;
         position: absolute;
         &.above-loadmore,
         &.under-loadmore {
            position: relative;
            display: block;
            top: auto;
            width: 68px;
         }
      }
      @media (max-width: 991px) {
         max-width: 85px;
         margin-bottom: 25px;
         margin-left: auto;
         margin-right: auto;
      }
   }
   @include element("title") {
      border-bottom: 0.7px solid rgba(110, 188, 233, 0.5);
   }
   @include element("content") {
      padding: 20px 0;

      @include element("readmore") {
         font-size: 13px;
         border-bottom: 1px solid $color_gray;
         color: #979797;
         &:hover {
            color: $color_yellow;
            border-bottom: 1px solid $color_yellow;
         }
      }
      @include element("des") {
         font-size: 15px;
         line-height: 1.2;
         font-weight: 300;
         @media (max-width: 991px) {
            display: initial;
         }
         @media (max-width: 766px) {
            font-size: 14px;
         }
      }
      @include element("loadmore") {
         border: 1px solid #3c2716;
         border-radius: 20px;
         color: $color-black;
         padding: 8px 26px 10px;
         position: relative;
         text-transform: uppercase;
         z-index: 2;
         background: #fff;
         font-size: 18px;
         font-weight: 700;
         &:hover {
            background: $color_brown_dark;
            color: #ffffff;
         }
         .text-load {
            &.hide {
               opacity: 0;
               visibility: hidden;
            }
         }
      }
   }

   @include element("item") {
      position: relative;
      &.is-hidden {
         opacity: 0;
         transition-property: opacity, transform, -webkit-transform;
      }
      &.fade-in {
         opacity: 1;
         transform: translateZ(0);
      }
      @media (min-width: 992px) {
         width: calc(100% - 77px);
         background-color: #fff;
      }
      &.left {
         .oph-timeline {
            @include element("left") {
               @media (max-width: 991px) {
                  order: 2;
               }
            }
            @include element("right") {
               p {
                  @media (min-width: 992px) {
                     padding-right: 41px;
                  }
               }
            }
         }
      }
      &.right {
         .oph-timeline {
            @include element("left") {
               order: 2;
            }
            @include element("right") {
               p {
                  @media (min-width: 992px) {
                     padding-left: 41px;
                  }
               }
            }
         }
         .oph-img {
            border-top-right-radius: 5px;
         }
         &:before {
            position: absolute;
            left: -20px;
            border-right: 25px solid #fff;
         }
      }
   }
}

.lds-ellipsis {
   position: absolute;
   top: 0;
   left: 23px;
   width: 100%;
   height: 100%;
   &.show {
      span {
         opacity: 1;
         visibility: visible;
         margin-left: 2px;
      }
   }
   span {
      position: absolute;
      top: 47%;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $color-gray;
      opacity: 0;
      visibility: hidden;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
      transform: translateY(-50%);
      transition: 250ms ease-in-out;
      &:nth-child(1) {
         left: 12px;
         animation: lds-ellipsis1 0.6s infinite;
      }
      &:nth-child(2) {
         left: 12px;
         animation: lds-ellipsis2 0.6s infinite;
      }
      &:nth-child(3) {
         left: 26px;
         animation: lds-ellipsis2 0.6s infinite;
      }
      &:nth-child(4) {
         left: 45px;
         animation: lds-ellipsis3 0.6s infinite;
      }
   }
}

@keyframes lds-ellipsis1 {
   0% {
      transform: scale(0);
   }
   100% {
      transform: scale(1);
   }
}

@keyframes lds-ellipsis2 {
   0% {
      transform: translate(0, 0);
   }
   100% {
      transform: translate(19px, 0);
   }
}

@keyframes lds-ellipsis3 {
   0% {
      transform: scale(1);
   }
   100% {
      transform: scale(0);
   }
}
