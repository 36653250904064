  @font-face {
    font-family: "borne";
    src: url("/build/fonts/borne/borne.eot?oqy7i3");
    src: url("/build/fonts/borne/borne.eot?oqy7i3#iefix") format("embedded-opentype"),
    url("/build/fonts/borne/borne.ttf?oqy7i3") format("truetype"),
    url("/build/fonts/borne/borne.woff?oqy7i3") format("woff"),
    url("/build/fonts/borne/borne.svg?oqy7i3#oph") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="bornei-"], [class*=" bornei-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'borne' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .bornei-PICTO_vector_52 {
    &:before {
      content: "\e900";
    }
    &:hover:before {
      content: "\e904";
    }
  }
  .bornei-PICTO_vector_53 {
    &:before {
      content: "\e901";
    }
    &:hover:before {
      content: "\e905";
    }
  }
  .bornei-PICTO_vector_54 {
    &:before {
      content: "\e902";
    }
    &:hover:before {
      content: "\e906";
    }
  }
  .bornei-PICTO_vector_55 {
    &:before {
      content: "\e903";
    }
    &:hover:before {
      content: "\e907";
    }
  }
  .bornei-PICTO_vector_60 {
    &:before {
      content: "\e908";
    }
    &:hover:before {
      content: "\e909";
    }
  }
  .bornei-PICTO_vector_56:before {
    content: "\e904";
  }
  .bornei-PICTO_vector_57:before {
    content: "\e905";
  }
  .bornei-PICTO_vector_58-58:before {
    content: "\e906";
  }
  .bornei-PICTO_vector_59:before {
    content: "\e907";
  }

  .bg_orange {
    background: #f9b234;
  }
  .bg_bluelight {
    background: #ebf8ff;
  }

  .grayscale {
    filter: grayscale(100%);
  }

  .borne_layout {
    padding: 0;
    height: 1080px !important;

    &.heightvh100 {
      height: 100vh;
    }

    &.tatoo_start {
      background: url(../../images/borne/tatoo_borne2.png) top left no-repeat;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 0;
        height: 400px;
        background: url(../../images/borne/fond_bleu.png) center top no-repeat;
        background-size: cover;
      }

      &.vh550 {
        &:before {
          height: 550px;
        }
      }

    }

    &.tatoo_start2 {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 0;
        height: 80vh;
        background: url(../../images/borne/fond_bleu.png) center top no-repeat;
        background-size: cover;
      }
    }

    .header-borne {
      position: relative;
      z-index: 1001;
    }

    .section-borne-format-title {
      color: #a2cfe9;
      font-size: 138px;
      font-weight: 700;
      line-height: 1.1;

      span {
        display: block;
        font-size: 77px;
        text-transform: uppercase;
      }
    }

    .section-borne-title {
      color: #a2cfe9;
      font-size: 138px;
      font-weight: 700;
      line-height: 1.1;

      @media (max-width: 1191px) {
        font-size: 100px;
      }

      @media (max-width: 767px) {
        font-size: 60px;
      }

      span {
        display: block;
        font-size: 50px;
        text-transform: uppercase;

        @media (max-width: 1191px) {
          font-size: 40px;
        }
        @media (max-width: 767px) {
          font-size: 30px;
        }
      }
    }

    .section-borne-title2 {
      color: #a2cfe9;
      font-size: 70px;
      font-weight: 700;
      line-height: 1.1;
      text-transform: uppercase;
      @media (max-width: 1191px) {
        font-size: 40px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
      }
    }

    .title-flag {
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      color: $color_brown_dark_02;
    }

    .title-flag2 {
      font-size: 35px;
      font-weight: 700;
      text-transform: uppercase;
      color: $color_brown_dark_02;
    }

    .borne_services {
      background-color: #e8f1f7;
      height: 935px
    }

    .borne_image_choice {
      max-height: 350px;
      width: auto;
    }

    .borne_format_image_choice {
      //max-height: 350px;
      min-width: 300px;
    }

    .backlink {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50px;
      font-size: 77px;
      color: #341f0e;
    }

    .navigation_top_right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 50px;
      font-size: 77px;
      color: #f9b234;
      line-height: 1;

      a {
        line-height: 1;
        color: #341f0e;
      }
    }

    .borne-demandeur-picto {
      max-height: 350px;
    }

    .section-borne-subtitle {
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .section-borne-format-subtitle {
      color: #fff;
      font-size: 35px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .borne_card_container {
      width: 100%;
    }

    .services-btn-aide {
      font-size: 25px;
    }

    .profile_image_choice {
      max-height: 350px;
      width: auto;
    }

    .borne_card {
      //background: #a2cfe9;
      display: block;
      min-height: 500px;
      //border-radius: 5px;
      .info {
        color: #fff;
        font-size: 20px;
        text-align: center;
        font-weight: 700;
      }

      .title-flag {
        font-size: 18px;
        font-weight: 500;
      }

      .title-service {
        font-size: 25px;
        font-weight: 700;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .title_column {
      text-align: center;
      color: #71bce6;
      font-size: 22px;
      font-weight: 700;
    }

    .btn-aid-item {
      background: none;
      border: 0;
      box-shadow: none;

      &:hover {
        opacity: 0.7;
      }
    }

    .btn-aid-item img {
      width: 363px;
    }

    #form_simulation {
      font-size: 20px !important;
      font-weight: 400 !important;
    }

    .simulation-section {
      min-height:936px !important;
      padding: 3rem !important;
    }

    .simu-radio .bmd-radio {
      &:before {
        width: 2.25rem !important;
        height: 2.25rem !important;
      }
      &:after {
        width: 2.25rem !important;
        height: 2.25rem !important;
      }
    }
    .simu-radio .radio {
      margin: 0 2rem;
    }
    .simu-radio .radio .bmd-radio{
      margin: -1rem -2rem !important;
    }
    .simu-radio .radio span{
      font-size: 20px !important;
      font-weight: 400 !important;
    }

    .simulation-page .section-aid {
      background: #ebf8ff;
      min-height: 1080px;
    }
    .previous_step {
      font-size: 35px;
      position: absolute;
      left: 0;
      top: 3.6rem;
      cursor: pointer;
    }
    .maxheight400 {
      max-height: 400px;
    }

    .simulation-page .section-result .result-panel h3 {
      padding-top: 1rem;
    }

    .simulation-section label{
      font-size: 20px;
      font-weight: 400;
    }

    .simulation-section .section-title{
      font-size: 35px;
    }

    .section-title .simu-tooltip {
      font-size: 30px;
    }

    .simu-radio {
      justify-content: end;
    }

    .modal-dialog.modal-custom {
      max-width: 100%;
    }
    .modal-dialog {
      margin: 0 auto;
    }
    .modal-dialog .modal-content .modal-body img {
      max-width: 200px;
    }

    .primary-form.custom-form .form-group.bmd-form-group.is-focused .form-control, .primary-form.custom-form .form-group.bmd-form-group.is-filled .form-control {
      background: none;
      box-shadow: none;
    }

    &.demandeur .section-header {
      padding-top: 25px;
      padding-bottom: 50px;
    }

    &.demandeur .list-step-form {
      padding: 30px 150px;
    }

    &.demandeur .step-section.demandeur-form-step-cause .primary-form {
      padding: 50px 0 0;
    }

    .youtube_container {
      position: relative;
      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        z-index: 100;
        background: transparent;
      }
    }

    //page de connexion
    #select-profil-form {
      .borne_form_connexion_input{
        height: 80px !important;
        margin-bottom: 40px !important;
      }
      .borne_form_connexion_input label{
        transform: translateY(-80%) !important;
      }
      .borne_form_connexion_input label, .borne_form_connexion_input input{
        font-size: 25px !important;
        font-weight: 300 !important;
      }
      input.form-control{
        font-size: 30px !important;
      }
      .form-control {
        padding-top: 20px !important;
      }
      #file_number-error {
        font-size: 18px !important;
      }
    }
    .borne_modal_activation_compte {
      .form-group.recaptcha {
        height: 0px !important;
      }
    }
  }

  .scroll-bar{
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scroll-bar::-webkit-scrollbar {
    display: none;
  }

  //page Simulation

  //section simu demande d'aide
  .simulation-section span.select2-selection{
    height: 60px !important;
  }

  .simulation-section .custom-form .form-group {
    height: 60px;
  }

  .simulation-section .bmd-label-floating {
    font-size: 18px !important;
  }

  .simulation-section .select2-selection .select2-selection__placeholder{
    font-size: 18px;
   }



  .simulation-section .select2-selection__rendered{
    &:before {
      font-size: 15px !important;
    }
    &:after {
      font-size: 15px !important;
    }
  }

  .borne_layout li.select2-results__option{
      font-size: 18px !important;
  }

  .simulation-section #simulation_submit{
     font-size: 20px !important;
   }

  .simulation-section input.form-control{
    font-size: 20px !important;
  }

  .session-borne {
    .select2-selection__rendered{
      padding-top: 0.5rem !important;
      font-size: 20px !important;
    }
    label.bmd-label-floating{
      font-size: 18px !important;
    }
    //** MODAL SOUMIS
    #dossier_soumis_dialog {
      .modal-content {
        height: 830px;
      }
      .modal-body h3 {
        font-size: 40px !important;
      }
      .dossier-soumis-content p{
        font-size: 22px !important;
      }
      #form_demande_survey {
        text-align: center;
        .primary-select b {
          font-size: 20px !important;
        }
        label.commentaire-title {
          font-size: 1.2rem !important;
        }
        #rating {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 1rem;
        }
        #rating:not(:checked) > label {
          font-size: 40px;
          margin: 0 1rem;
        }
        textarea {
          font-size: 20px;
        }
        textarea::placeholder {
          font-size: 20px !important;
        }
      }
    }
  }


  //section simulation Résultats
  .borne_layout .section-result {
    &:before{
      width: 400px;
      height: 700px;
      top: 9.8rem;
    }
  }

  .borne_layout .form-group{
    height: 70px;
    margin-top: 20px !important;
    font-size: 20px !important;
    input.form-control{
      padding-top: 0.5rem !important;
      font-size: 20px !important;
    }
    .error-dob-message {
      color:red;
      margin-bottom:12px;
      font-size: 18px;
    }
  }


  .simulation-section .maxheight800 {
    max-height: 800px;
    margin-top: -100px;
  }

  .simulation-section .result-panel {
    h3 {
      padding-top: 0 !important;
      font-size: 70px !important;
      font-weight: 500 !important;
    }
    b{
      min-width: 100%;
      line-height: 1.5 !important;
      font-size: 25px !important;
    }
    p{
      min-width: 100%;
      font-size: 20px !important;
    }
    .btn{
      font-size: 20px !important;
    }
  }

  //simulation modal creation acces
  .simu-create-acces .modal-body{
    .modal-content{
      height: 1000px;
      width: 100%;
    }
    h3{
      font-size: 40px;
    }
    .modal-desc{
      p{
        font-size: 20px;
      }
    }
    .text-secondary small{
      font-size: 18px;
    }
    #simulation_user_email-error{
      font-size: 20px !important;
      margin-top: 20px !important;
    }

    #simulation_user_confirm_email-error{
      font-size: 20px !important;
      margin-top: 20px !important;
    }

    .btn {
      margin-top: 16px;
    }

  }

  .borne_layout {
    .btn {
      font-size: 20px !important;
    }

    //**  HEADER btn espace client
    .borne_btn_connexion{
      border-radius: 50% !important;
      border-width: 2.3px !important;
      width: 75px;
      padding: 0.8rem 1rem !important;
      margin-bottom: 26px;
    }
    .borne_btn_connexion i {
      font-size: 38px;
      font-weight: bold;padding: 0;
    }
    .dropdown-menu {
      width: 300px;
      top: 78px;
      left: -172px;
    }
    .header-borne .btn span{
      font-size: 18px !important;
    }
    .borne-btn-header {
      width: 100%;
    }

    //Page reset password
    .update-password-section {
      font-size: 22px !important;

      h3 {
        font-size: 40px !important;
      }

      .error {
        font-size: 17px !important;
      }

      #tooglePassword_new_pass, #tooglePassword_confirm_pass {
        font-size: 40px;
      }
    }

    //Page mot de passe réinitialisé
    .borne-update-password {
      font-size: 22px !important;

      h3 {
        font-size: 40px !important;
      }

      .btn {
        font-size: 20px !important;
      }
    }

    //Page espace demandeur
    .borne-espace-demandeur-header {
      padding: 0;

      .section-header {
        min-height: 53px;
        width: 100%;
        padding: 30px 30px 20px !important;
        background-color: #f9b233 !important;

        .requested strong, .requested span,
        .file-number strong, .file-number span,
        .city strong, .city span,
        {
          color: #000000;
          font-size: 20px;
        }

        .requested, .file-number {
          border-right: 2px #fff solid;
        }
      }

      .list-step-form {
        margin: 0 !important;
      }

      .list-step-form ul.list-step-form__inner {
        margin: 0 !important;

        a {
          font-size: 25px;
        }
      }

      .custom-spacing-container {
        padding: 0;
      }

      .demandeur-header__info {
        label.bmd-label-static {
          font-size: 18px !important;
        }

        .select2-selection__arrow b:after {
          font-size: 20px !important;
        }

        .open {
          bottom: -110px;
        }

        .select2-selection--single {
          height: 60px !important;
        }

        .select2-results__option {
          font-size: 20px;
        }
      }

      .demandeur-header__status {
        .flex-status-container {
          .status {
            font-size: 14px;
          }
        }

        span.icon {
          font-size: 25px;
        }
      }


    }

    .borne-espace-demandeur-content {
      .step-submit .btn {
        width: 200px !important;
      }

      //*** MOTIF
      .demandeur-step-title {
        .number {
          flex: 0 0 45px;
          width: 45px;
          height: 45px;
          font-size: 30px;
        }

        h3 {
          font-weight: 500;
          font-size: 40px;
        }
      }

      .demandeur-form-step-cause .form-group.textarea textarea.form-control {
        font-size: 20px !important;
      }

        #demande_cause > .custom-checkbox {
        width: 33.33333%;
        padding-left: 0;

        label.cause_label {
          font-size: 18px;
          font-weight: 400;
        }

        .cause_label:before {
          border-radius: 50%;
          min-width: 25px;
          height: 25px;
          margin-right: 20px;
        }
      }

      .error-message .error {
        font-size: 18px !important;
      }

      .demandeur .step-submit {
        margin-top: 30px;
      }

      #demande_cause .custom-checkbox b {
        font-size: 18px;
      }

      .icon-download-document {
        font-size: 22px;
        margin-right: 12px;
      }

      .form-upload-single-link .text {
        font-size: 16px;
      }

      #demande_cause textarea::placeholder {
        font-size: 18px !important;
      }

      .demandeur-step-title {
        justify-content: center !important;
      }

      .title {
        font-size: 20px !important;
      }

      .borne-step-coordonnees,
      .demandeur-form-step-family {
        .form-group {
          height: 60px;

          label.bmd-label-floating {
            font-size: 17px !important;
          }
        }

        .select2-selection--single {
          height: 60px !important;
        }

        small.asterisque-info {
          font-size: 16px !important;
        }
      }

      //*** COORDONNÉES
      .borne-step-coordonnees {
        .bmd-form-group {
          width: 100% !important;
          max-width: 100% !important;
        }
      }

      //*** COMP FAM
      .demandeur-form-step-family {
        .step-submit {
          margin-top: 20px !important;
        }

        .info-person-form-wrap {
          width: 100%;
        }

        .show-role {
          font-size: 18px !important;
        }

        .bmd-label-static {
          font-size: 18px !important;
          top: 10% !important;
        }

        .label-title {
          font-size: 18px !important;
        }

        .select2-selection__rendered {
          padding-top: 1.2rem !important;
        }

        .pmr-radio {
          height: 100%;
          align-items: center;

          .label-title {
            padding-top: 0 !important;
            margin-bottom: 15px !important;
            margin-left: 1rem;
            font-size: 20px !important;
          }

          .d-flex {
            margin: 1rem 2rem 2rem 2rem;
            align-items: center;
          }

          .d-flex .bmd-form-group {
            margin-right: 1rem;
          }

          .d-flex .bmd-form-group .bmd-radio {
            margin: -0.5rem !important;
          }

          .bmd-form-group .radio span {
            font-size: 18px !important;
            margin: 0 0.8rem !important;
          }

          .bmd-form-group {
            .bmd-radio:before {
              width: 1.5rem;
              height: 1.5rem;
            }

            .bmd-radio:after {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }

        .form-group-upload {
          font-size: 20px;

          .note-upload {
            font-size: 18px;
          }

          .form-group-upload__inner {
            .form-upload-single-link, .form-upload-single-link__label {
              font-size: 18px !important;
            }

            .icon-download-document {
              font-size: 25px !important;
            }

            .text {
              font-size: 20px !important;
            }
          }
        }

        .form-upload-single-link a{
          font-size: 18px !important;
        }
      }

      //**  RESSOURCE
      .borne-step-resource {
        p.note {
          font-size: 20px;
          margin-top: 100px;
        }
        .select2-selection {
          height: 60px !important;
        }
        .selection__rendered {
          padding-top: 1.2rem !important;
        }
        .select2-selection__rendered {
          padding-top: 1.2rem !important;
        }
        .bmd-label-static {
          top: 8% !important;
          font-size: 18px !important;
        }
        .select2-selection__rendered {
          padding-top: 1.3rem !important;
        }
        input.form-control {
          padding-top: 10px !important;
          font-size: 22px !important;
        }
        .form-group {
          height: 60px !important;
        }
        .line-total {
          height: 60px !important;
          font-size: 20px !important;
        }
        small {
          font-size: 18px;
        }
        .label-title {
          font-size: 20px;
        }
        .radio span {
          font-size: 18px;
        }

      }

      //**  MATERIAUX
      .borne-step-material {
        .note {
          font-size: 20px !important;
        }
        .table thead th {
          font-size: 20px !important;
          font-weight: 400 !important;
        }
        .table tbody td {
          font-size: 20px !important;
        }
        .amount_total {
          font-size: 2rem !important;
        }
      }

      //**  CONSTRUCTION
      .borne-step-construction {
        input.form-control {
          padding-top: 10px !important;
        }
        .select2-selection {
          height: 60px !important;
        }
        .select2-selection__rendered {
          padding-top: 1rem !important;
        }
        .form-upload-single-link__label {
          font-size: 18px;
          font-weight: 400 !important;
        }
        label.specificite {
          font-size: 18px;
        }
        .form-group {
          height: 60px !important;
          label.bmd-label-floating {
            font-size: 18px !important;
          }
        }
        .bmd-form-group .radio span {
          font-size: 18px;
        }
        .form-group-upload__inner {
          margin-top: 0;
        }
        .construction-cadastre-info p{
          font-size: 18px;
        }
      }

      //**  RECAP
      .demandeur-form-step-summary {
        .label, .text-default {
          font-size: 18px !important;
        }
        .h5, h5 {
          font-size: 1.5rem !important;
        }
        .h6, h6 {
          font-size: 1.3rem !important;
        }
        .resources {
          font-size: 19px !important;
        }
        .total {
          font-size: 18px !important;
          .box_total {
            font-size: 20px !important;
          }
        }
        .step-submit {
          margin-top: 30px !important;
        }
        .label-title {
          font-size: 18px;
        }
        .bmd-form-group .radio span {
          font-size: 18px;
        }
        .pl-radio {
          padding-left: 1.8rem !important;
        }
        .construction-summary {
          width: 100%;
        }
        .table thead th {
          font-size: 18px !important;
        }
        .error-message .error {
          font-size: 25px !important;
        }
        .text-default {
          font-size: 20px !important;
        }
        #error-dob-message {
          font-size: 20px !important;
          color:red !important;
        }
        .table thead th {
          font-size: 20px !important;
        }
        .table tbody td {
          font-size: 19px !important;
        }
      }
    }

    .form-upload-single-link__label i.icon-tooltip {
    font-size: 15px !important;
  }

  }
  //** MODAL PMR
  .borne-modal-pmr {
    .modal-content {
      height: 600px !important;
      .modal-desc p{
      font-size: 20px !important;
      text-align: center !important;
      line-height: 26px;
      }
      label.popup_compo_fam_pmr {
        font-size: 1.3rem !important;
        text-align: center !important;
        padding: 0 1rem !important;
      }
      .d-flex {
        justify-content: center;
        .bmd-form-group {
          padding-top: 0;
          margin: 1rem 2rem !important;
          label {
            font-size: 2rem;
            margin: 1rem;
            .bmd-radio {
              padding: 0;
              margin: 0.5em -0.5em !important;
              &:before {
                width: 1.5rem !important;
                height: 1.5rem !important;
              }
              &:after {
                width: 1.5rem !important;
                height: 1.5rem !important;
              }
            }
          }
        }
      }
    }
  }



  .borne_layout .demande-alert-message {
    font-size: 25px !important;
  }