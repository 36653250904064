.oph-banner-information {
  display: block;
  position: relative;
  z-index: 10;
  &.overlay {
    &:before {
      content: '';
      position: absolute;
      z-index: 11;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color-black, 0.4);
    }
  }
  .oph-content {
    position: absolute;
    top: 50%;
    left: 10%;
    color: #fff;
    z-index: 12;
  }
}

.oph-banner-1 {
  .banner-title {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    left: 0;
    @media (max-width: 600px) {
      padding: 0;
      justify-content: center;
    }
  }

  h1 {
    color: #ffffff;
    font-weight: 500;
    font-size: 50px;

    @include respond-below('md') {
      font-size: 25px;
    }
    @include respond-below('xs') {
      font-size: 18px;
    }
  }

  .banner-img {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.2);
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (max-width: 675px) {
      img {
        height: 223px;
        object-fit: cover;
      }
    }
  }

  .banner-content {
    position: absolute;
    top: 50%;
    left: 10%;
    color: #fff;
    transform: translate(0, -80%);
    width: auto;
    .btn-back {
      display: flex;
      align-items: center;
      line-height: 60px;
      i {
        font-size: 40px;
        transform: rotate(90deg);
      }
    }
    @include respond-below('md') {
      left: 10%;
      transform: translate(0, -70%);
      text-align: left;

      i {
        font-size: 25px !important;
      }
    }
  }
}

.blue-banner {
  padding-top: 25px;
  background: url(../../images/blue-banner-mobile.png);

  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 0;
  padding-bottom: 2rem;

  @include respond-above('sm') {
    padding-top: 80px;
    background: url(../../images/blue-banner-desktop.png);
    background-size: cover;
    background-position-y: 130%;

    margin-bottom: 3rem;
  }

  @include respond-above('md') {
    background-position-y: 100%;
    margin-bottom: 5rem;
  }

  // @include respond-below('xs') {
  //   background-size: 120% 115%;
  //   background-position-y: -100px;
  // }

  .blue-banner__title {
    @include respond-above('sm') {
      text-align: left;
    }
    @include respond-above('md') {
      padding-top: 3rem;
      flex: 2 0 60%;
    }
    @include respond-above('lg') {
      padding-left: 3rem;
    }

    @include respond-above('xl') {
      padding-left: 5rem;
    }
  }

  .blue-banner__image {
    @include respond-above('md') {
      padding-left: 0;
      padding-right: 5rem;
      flex: 1 0 40%;
    }
    @include respond-above('xl') {
      padding-right: 10rem;
    }
    img {
      @include respond-below('sm') {
        max-width: 200px !important;
      }

      @include respond-below('xs') {
        max-width: 150px !important;
      }
    }
  }

  h1,
  .description {
    color: $color_blue_light;
    // font-size: 50px;
    font-size: 23px;
    @include respond-above('xxs') {
      font-size: 25px;
    }
    @include respond-above('sm') {
      font-size: 30px;
    }
    @include respond-above('md') {
      font-size: 35px;
    }
    @include respond-above('lg') {
      font-size: 50px;
    }
  }

  h1 {
    margin-bottom: 0;
    font-weight: 500;
  }
  .description {
    font-weight: 300;
  }
}
