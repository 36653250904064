.select2-container {
  width: auto !important;
  min-width: 100px;
  outline: none;
  .select2-dropdown {
    .select2-results {
      &__option {
        font-size: 14px;
        &--highlighted {
          background-color: $color_yellow;
        }
      }
    }
  }
  .selection {
    .select2-selection {
      height: 45px;
      border-radius: 5px;
      border-color: $color_gray !important;
      outline: none;
      .select2-selection__rendered {
        line-height: 33px;
        padding-right: 2rem;
        padding-left: 1rem;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 300;
        color: $color_black;
        .select2-selection__placeholder {
          color: $color_gray_light_01;
        }
        &:after {
          content: '\e903';
          font-family: 'oph';
          position: absolute;
          right: 15px;
          transform: rotate(90deg);
          color: $color_black;
          font-weight: bold;
          transition: 300ms ease-in-out;
          font-size: 10px;
        }
      }
      .select2-selection__arrow {
        display: none;
      }
    }
  }
  &.select2-container--open {
    .selection {
      .select2-selection {
        border-color: $color_yellow !important;

        .select2-selection__rendered {
          color: $color_yellow;
          font-weight: 500;
          &:after {
            color: $color_yellow;
            transform: rotate(-90deg);
          }
        }
      }
    }
    .select2-dropdown {
      border-color: $color_yellow;
    }
  }
}
