$color-gray: #c0c0c0;
$color_gray_light: #f7f7f7;
$color_gray_light_01: #c0c0c0;
$color_gray_light_02: #e3e3e3;
$color_gray_light_03: #d8d8d8;
$color_gray_light_04: #d3d3d3;
$color_gray_light_05: #979797;

$color_gray_dark: #484848;
$color_gray_dark_02: #dadada;

$color_brown: #7d4e24;
$color_brown_light: #d3c5b9;
$color_brown_light2: #b98954;
$color_brown_light3: #f9b233;
$color_brown_dark: #3c2716;
$color_brown_dark_02: #341f0e;

$color_blue: #5fb5ff;
$color_blue_light: #6ebce9;
$color_blue_light_02: #e9f5fc;

$color_black: #000;
$color-dark-black: #3b3a3a;
$color-super-light-gray-04: #e0e0e0;
$color-super-light-gray-09: #bbbbbb;
$color_black_light: #d8d8d8;
$color_white: #fff;
$color_yellow: #f9b233;

$color_primary_bg: $color_yellow;
$color_footer_bg: $color_brown_dark;
$color_text: $color_brown_dark;
$color_border: #e3e3e3;
$color_hr: $color_gray_light_05;

$color_green: #009b37;

// Z-index:
$map_control_index: 999;
$map_detail_index: 1000;
$header_index: 1001;
$dropdown_menu_index: 100;
$footer_index: 1000;
$tooltip_index: 999;

// Height
$header_height_mobile: 60px;
