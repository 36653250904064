.home-page {
  #page-content {
    padding-bottom: 0;
  }

  // Padding for section with list
  .section-list {
    padding: 25px 0;
    @include respond-above('sm') {
      padding: 50px 0;
    }
    @include respond-above('md') {
      padding: 100px 0;
    }
  }
  // Section Slider
  .section-slider {
    .slide_item {
      position: relative;
      display: none;

      overflow: hidden;
      outline: none;
      @include respond-above('xs') {
        height: 350px;
      }
      @include respond-above('md') {
        height: 400px;
      }
      @include respond-above('xxxl') {
        height: 510px;
      }
    }

    .slick-track {
      background-color: #f7f7f7;
      @include respond-above('xs') {
        height: 350px;
      }
      @include respond-above('md') {
        height: 400px;
      }
      @include respond-above('xxxl') {
        height: 510px;
      }
    }

    .slide_image {
      position: relative;
      width: 100%;
      height: auto;

      @include respond-above('sm') {
        height: 350px;
        min-height: 300px;
        width: auto;
        max-width: none;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
      }
      @include respond-above('md') {
        min-height: 400px;

      }
      @include respond-above('xl') {
        width: 100%;
        height: auto;
      }
      @include respond-above('xxxl') {
        min-height: 510px;
      }
    }

    .container {
      position: relative;
      height: 100%;
    }

    .content-slide {
      position: relative;
      padding: 1rem 2rem;
      background-color: $color_gray_light;
      text-align: center;
      @include respond-above('sm') {
        position: absolute;
        text-align: left;
        background-color: $color_gray_light;
        top: 50%;
        transform: translate(0, -50%);
        padding: 2rem;
        max-width: 350px;
        left: 1rem;
      }

      @include respond-above('md') {
        padding: 50px 50px 30px 50px;
        max-width: 420px;
        left: 2rem;
      }
      @include respond-above('lg') {
        left: 4rem;
      }
      @include respond-above('xl') {
        left: 10rem;
      }

      h1 {
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        color: $color_brown_dark_02;

        @include respond-above('md') {
          font-size: 37px;
          font-weight: 700;
          line-height: 47px;
          color: $color_brown_dark;
        }
      }

      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        margin-bottom: 1rem;

        @include respond-above('md') {
          font-size: 22px;
          line-height: 31px;
          margin-bottom: 1.5rem;
        }
      }
      a {
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
        padding: 0.75rem 1rem;
        @include respond-above('md') {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
    .slider-navigation {
      margin-top: 1rem;
      font-size: 20px;
      @include respond-above('md') {
        font-size: 30px;
        margin-top: 2rem;
      }
      .btn-navigation {
        padding: 1rem;
        &:hover {
          color: $color_yellow;
        }
      }
      // .prev {
      //   &:hover {
      //     transform: translateX(-5px);
      //   }
      // }
      // .next {
      //   &:hover {
      //     transform: translateX(5px);
      //   }
      // }
    }
  }

  // Section Actualités & événements (NEWS)
  .section-actualites {
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: $color_brown_dark;
      @include respond-above('sm') {
        font-size: 29px;
      }
    }

    .actualites-item {
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      height: unset;

      @include respond-above('sm') {
        overflow: hidden;
        height: 300px;
      }

      .image_link {
        overflow: hidden;
        display: block;
        width: 100%;

        img {
          display: block;
          position: relative;

          width: 100%;
          transition: all 0.3s;

          @include respond-above('sm') {
            position: absolute;
            width: auto;
            max-width: none;
            min-height: 300px;
            left: 50%;
            transform: translate(-50%, 0);
            top: 0;
            min-width: 100%;
          }
        }

        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }

      .left_box {
        display: block;
        position: relative;

        background: $color_yellow;
        padding: 10px 20px;
        text-align: left;
        opacity: 0.85;

        @include respond-above('sm') {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 225px;
          padding: 30px;
        }

        .date {
          font-weight: 300;
          font-size: 10px;
          color: $color_brown_dark;
          @include respond-above('sm') {
            font-size: 14px;
          }
        }

        h2, h3 {
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 17px;
          font-weight: 500;
          line-height: 22px;
          color: $color_brown_dark;

          @include respond-above('sm') {
            margin: 15px 0;
            font-size: 17px;
          }
        }

        .actu_intro {
          display: block;

          font-size: 13px;
          font-weight: 300;
          color: $color_brown_dark;
          @include respond-above('sm') {
            font-size: 16px;
            padding: 0 0 15px;
            min-height: 90px;
          }
          a {
            font-size: 16px;
          }
        }

        a {
          font-size: 30px;
          color: $color_white;
          @include respond-above('sm') {
            font-size: 40px;
          }
          i {
            font-weight: 700;
          }
          &:hover {
            text-decoration: none;
            color: $color_brown_dark;
          }
        }
      }

      &:hover {
        -webkit-box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.1);

        .image_link {
          img {
            transform: scale(1.05);
            @include respond-above('sm') {
              transform: scale(1.05) translate(-50%, 0);
            }
          }
        }

        .left_box {
          a {
            text-decoration: none;
            color: $color_brown_dark;
          }
        }
      }
    }

    .link_light {
      text-align: right;
    }
  }

  // Section Map
  .section-map {
    // Map
    .map-leaflet {
      font-family: 'Ubuntu', sans-serif;
      height: 350px;
      display: block;
      position: relative;
      @include respond-above('xl') {
        height: 400px;
      }
      .map-control-panel {
        position: absolute;
        z-index: 999;
        left: 6px;
        height: 100%;
        @include respond-above('sm') {
          left: 5rem;
        }
        &.show {
          .dropdown-toggle {
            &:after {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
        }
      }

      .place-description {
        margin-top: 4rem;
        padding-left: 0.5rem;
        font-size: 31px;
        font-weight: 700;
        line-height: 33px;

        @include respond-below('sm') {
          display: none;
          // line-height: 1.2;
          // margin-top: 1rem;
          // font-size: 25px;
        }

      }

      // Dropdown botton
      .dropdown-toggle {
        color: $color_text;
        background-color: $color_white;
        padding-right: 40px;
        border-radius: 5px;
        min-width: 250px;
        text-align: left;

        span {
          padding-right: 2rem;
        }
        &:hover,
        &:active {
          background-color: $color_primary_bg;
        }
        &:after {
          content: '\e903';
          font-family: oph;
          position: absolute;
          right: 1rem;
          transform: translateY(-50%) rotate(90deg);
          color: #000;
          font-weight: 700;
          transition: 0.3s ease-in-out;
          border: none;
          top: 50%;
          width: 10px;
          height: 20px;
          margin: 0;
          vertical-align: middle;
        }
      }

      // Dropdown list
      .dropdown-menu {
        max-height: 160px;
        border-radius: 5px;
        max-width: 100%;
        min-width: 250px;
        @include respond-above('xl') {
          max-height: 220px;
        }
        .dropdown-item {
          padding: 0.5rem;
          color: $color_yellow;
          min-height: auto;

          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover,
          &:focus {
            color: $color_white;
            background-color: $color_yellow;
          }
        }
      }
    }
  }

  .section-map-mobile {
    .section-filter {
      padding: 10px 25px;
      margin-top: 0;
    }

    .place-description-mobile-mobile {
      background-color: #f7f7f7;
      line-height: 2.2;
      color:#7d4e24;
      font-weight:bold;
      padding: 25px;
      margin-bottom: 0;
      @include respond-above('md') {
        display: none;
      }
    }
    .dropdown-toggle-mobile {
      background-color: #fff;
      border: 1px solid #c0c0c0;
      border-radius: 5px;
      min-width: 0;
      text-align: left;
      height: 45px;
      text-transform: none;
      display: block;

      line-height: 33px;
      padding-right: 2rem;
      padding-left: 1rem;
      padding-top: 5px;
      font-size: 14px;
      font-weight: 300;
      color: #000;
      &:after {
        content: '\e903';
        font-family: 'oph';
        position: absolute;
        right: 15px;
        transform: rotate(90deg);
        color: #000;
        font-weight: bold;
        transition: 300ms ease-in-out;
        font-size: 10px;
      }
    }

    .dropdown-menu {
      width: 100%;
      .dropdown-item {
        width: 100%;
        max-width: 100%;
      }
    }

    .show {
      .dropdown-toggle-mobile {
        color: #f9b233;
        font-weight: 500;
        border: 1px solid #f9b233;

        &:after {
          transform: rotate(-90deg);
          color: #f9b233;
        }
      }
      .dropdown-menu {
        .dropdown-item:hover {
          background: #f9b233;
          color: #fff;
        }
      }
    }


  }
}
