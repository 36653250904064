/* Toolbar css
----------------------------------*/

#toolbar_simple {
  position: fixed;
  right: 0px;
  padding: 1px !important;
  z-index: 1001;
  top: 20%;
}

#toolbar {
  position: fixed;
  right: 0px;
  padding: 1px !important;
  top: 40%;
  z-index: 1001;
  animation-duration: 0.5s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    right: -100px;
  }

  to {
    right: 0px;
  }
}

#toolbar_home{
  position: fixed;
  right: -100px;
  padding: 1px !important;
  z-index: 1;
  top: 40%;
}
.navscroll_home #toolbar_home{
  z-index: 1;
  top: 40%;
  right: 0px;
  animation-name: c_bar;
  animation-duration: 0.5s;
}

@keyframes c_bar {
  from {
    right: -100px;
  }
  to {
    right: 0px;
  }
}

#toolbar_simulation{
  position: fixed;
  right: 0px;
  padding: 1px !important;
  z-index: 6000;
  top: 40%;
  animation-duration: 0.5s;
  animation-name: slidein;
}

.navscroll_simulation #toolbar_simulation{
  z-index: 1;
  top: 40%;
  right: -100px;
  z-index: 6000;
  animation-name: c_bar_simulation;
  animation-duration: 0.5s;
}
@keyframes c_bar_simulation {
  from {
    right: 0px;
  }
  to {
    right: -100px;
  }
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style-type: none;
}
.oph-header__toolbar-list__item>a, .oph-header__toolbar-list__item>div {
  margin-left: 60%;
  transition: width 2s, height 2s, transform 2s;
  color: transparent !important;
  padding: 0 8% 0 0 !important;
  display: flex;
  border-radius: 50% !important;
  /*background-color: #ff9933 !important;*/
  background-color: #f9b233 !important;
  align-items: center;
  justify-content: flex-end;
  height: 62px;
  z-index: 1;
}
.oph-header__toolbar-list__item>a:hover, .oph-header__toolbar-list__item>div:hover {
  margin-left: 0%;
  color: #000000 !important;
  border-radius: 30px !important;
  /*background-color: #f9b233 !important;*/
  padding: 0% !important;
}
.oph-header__toolbar-list__item .icon, .oph-header__toolbar-list__item .picto {
  width: 30px;
  margin-right: 10%;
  text-align: center;
  svg {
    width: 30px;
    height: auto;
  }
  .active {
    display: inline-block;
  }
  .inactive {
    display: none;
  }
}
.oph-header__toolbar-list__item-link {
  font-size: 13px;
}