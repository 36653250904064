.modal-dialog {
  @include respond-above('sm') {
    margin: 0 auto;
  }
  @include respond-above('md') {
    margin: 0.5rem auto;
  }
  @include respond-above('xl') {
    margin: 1.75rem auto;
  }
  &.modal-custom {
    @include respond-below('xs') {
      margin: 0;
      transform: translate(0, $header_height_mobile) !important;
    }

    @include respond-above('xs') {
      transform: translate(0) !important;
    }
    @include respond-above('md') {
      max-width: 800px;
    }
    // @include respond-above('xxl') {
    //   max-width: 1140px;
    // }
  }

  .modal-content {
    box-shadow: none;
    .close {
      position: absolute;
      font-size: 20px;
      top: 23px;
      right: 20px;
      z-index: 1;
      opacity: 1;
      @include respond-above('md') {
        font-size: 30px;
        top: 1rem;
        right: 1rem;
      }
      i {
        color: $color_black;
      }
    }

    // .modal-title {
    //   font-size: 20px;
    //   font-weight: 300;
    //   color: $color_brown_dark;
    // }
    .modal-header {
      justify-content: center;
      padding: 1rem 1rem 0;
      h1 {
        font-size: 19px;
        font-weight: 500;
        text-transform: uppercase;
        color: $color_brown;
      }
    }
    .modal-body {
      padding: 20px 25px 30px;
      max-height: 80vh;
      overflow: auto;
      @include respond-above('xs') {
        max-height: 70vh;
      }
      @include respond-above('md') {
        padding: 20px 30px 45px;
        max-height: 80vh;
      }

      @include respond-above('xl') {
        padding: 50px 80px 45px;
        max-height: unset;
      }
      img {
        max-width: 120px;
        margin-bottom: 20px;
        @include respond-above('sm') {
          max-width: unset;
        }
      }
      h3 {
        font-size: 20px;
        font-weight: 300;
        color: $color_brown_dark;
        margin-bottom: 30px;
        @include respond-above('md') {
          color: $color_brown_dark;
          font-size: 30px;
        }
      }
      p {
        font-size: 13px;
        font-weight: 300;
        line-height: 21px;
        @include respond-above('md') {
          font-size: 15px;
        }
      }
      .custom-form {
        margin-bottom: 0;
        @include respond-above('md') {
          max-width: 60%;
          margin: 0 auto;
          margin-top: 2rem;
        }
        // @include respond-above('xl') {
        //   max-width: 80%;
        // }
        .form-group {
          margin-bottom: 1rem;
          @include respond-above('md') {
            margin-bottom: 1rem;
          }
          &.has-error {
            margin-bottom: 2rem;

            @include respond-above('md') {
              margin-bottom: 1rem;
            }
            &.field-password {
              margin-bottom: 5rem;
              @include respond-above('sm') {
                margin-bottom: 4rem;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 8px;
    }
  }
  .btn-save {
    min-width: 150px;
    @include respond-above('xl') {
      max-width: 170px;
    }
  }
}

#create_account_dialog {
  .modal-content {
    //background
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      width: 166px;
      height: 152px;

      background-image: url('../../images/create-account-bg2.png');
      background-repeat: no-repeat;
      top: 0;
      background-size: cover;
      left: 40%;
      transform: rotate(75deg);

      @include respond-above('sm') {
        width: 278px;
        height: 252px;
        top: 30px;
      }
    }
  }
}

#attention_dialog, #attention_dialog_email_received {
  .modal-content {
    //background
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      width: 166px;
      height: 152px;

      background-image: url('../../images/create-account-bg2.png');
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(75deg);

      left: 20%;
      top: 50px;
      @include respond-above('sm') {
        width: 278px;
        height: 252px;
        left: 25%;
        top: 80px;
      }
    }
  }
}
