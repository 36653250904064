
.search-results__result {
   color: #3b3a3a;
   margin: 1.5rem 0;
   display: block;
}
.search-results__result .search-results__result__header {
   /*display: flex;*/
   align-items: center;
   justify-content: space-between;
   margin: .5rem 0;
}
.search-results__result .search-results__result__body {
   line-height: 1.5;
}