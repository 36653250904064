//.materiaux_block {
//   border-top: 2px solid $color_brown_dark;
//
//   .title_block {
//      font-size: 1.5rem;
//      color: $color_brown;
//      font-weight: 500;
//   }
//   .detail_amount_block {
//      opacity: 0;
//      transition: opacity 0.3s;
//      &.show {
//         opacity: 1;
//      }
//   }
//   .amount_total {
//      font-size: 1.5rem;
//      font-weight: 300;
//   }
//   .color_blue_light {
//      color: $color_blue_light;
//
//   }
//   .detail_list_link {
//      opacity: 0.5;
//      &:hover {
//         opacity: 1;
//      }
//   }
//}
//
//.card {
//   box-shadow: none;
//   .card-header {
//      border-top: 2px solid $color_blue_light;
//      border-bottom: 0;
//      &:first-child {
//         border-radius: 0;
//      }
//      .btn-accordion {
//         display: block;
//         width: 100%;
//         text-align: left;
//         padding-left: 1rem;
//         padding-right: 1rem;
//         text-transform: none;
//         font-size: 1.4rem;
//         margin: 0;
//         position: relative;
//         &:after {
//            content: "\e900";
//            font-family: oph !important;
//            font-style: normal;
//            font-weight: 400;
//            float: right;
//            transform: rotate(0deg);
//            transition: all 0.4s;
//            color: $color_brown_dark;
//            font-size: 2rem;
//         }
//         &:hover, &:focus, &:active {
//            background: none;
//            border: 0;
//         }
//         &.collapsed {
//            &:after {
//               transform: rotate(180deg);
//            }
//         }
//      }
//   }
//
//   .card-body {
//      input[type='number'].form-control2 {
//         -moz-appearance: number-input;
//         border: 1px solid $color-gray;
//         border-radius: 10px;
//         padding-left: 10px;
//         padding-right: 10px;
//         display: inline-block;
//         max-width: 80px;
//      }
//      .table {
//         .code {
//            white-space: nowrap;
//         }
//         .price {
//            white-space: nowrap;
//         }
//      }
//   }
//
//
//}
//
//
//.first_block_step-resource {
//   transition: all 0.3s;
//   visibility: visible;
//   opacity: 1;
//
//   &.hide {
//      visibility: hidden;
//      opacity: 0;
//      height: 0;
//   }
//}
//
//.materiaux_liste_detail {
//   visibility: hidden;
//   opacity: 0;
//   height: 0;
//   transition: all 0.3s;
//
//   &.show {
//      visibility: visible;
//      opacity: 1;
//   }
//
//   .block_title {
//      .btn-back {
//         font-size: 30px;
//         margin-right: 1rem;
//         vertical-align: middle;
//      }
//      span {
//         font-size: 25px;
//         font-weight: 500;
//         color: $color_brown_dark;
//      }
//   }
//
//   input[type='number'].form-control2 {
//      -moz-appearance: number-input;
//      border: 1px solid $color-gray;
//      border-radius: 10px;
//      padding-left: 10px;
//      padding-right: 10px;
//      display: inline-block;
//      max-width: 80px;
//   }
//   .table {
//      .code {
//         white-space: nowrap;
//      }
//      .price {
//         white-space: nowrap;
//      }
//   }
//   .qte {
//      width: 110px;
//   }
//   .actions {
//      width: 50px;
//      text-align: right;
//      .remove_unit {
//         background: none;
//         border: 0;
//         box-shadow: none;
//         color: $color_yellow;
//         font-size: 30px;
//      }
//   }
//}

.materiaux_block {
   border-top: 2px solid $color_brown_dark;

   .title_block {
      font-size: 1.5rem;
      color: $color_brown;
      font-weight: 500;
   }
   .detail_amount_block {
      opacity: 0;
      transition: opacity 0.3s;
      &.show {
         opacity: 1;
      }
   }
   .amount_total {
      font-size: 1.5rem;
      font-weight: 300;
   }
   .fix_amount_total{
      position: fixed;
      top: 1rem;
      padding: 1rem;
      border: 2px solid black;
      background-color: white;
      z-index: 1999;
   }
   .color_blue_light {
      color: $color_blue_light;

   }
   .detail_list_link {
      opacity: 0.5;
      &:hover {
         opacity: 1;
      }
   }
}

.card {
   box-shadow: none;
   .card-header {
      border-top: 1px solid $color_blue_light;
      border-bottom: 0;
      &:first-child {
         border-radius: 0;
      }
      .btn-accordion {
         display: block;
         width: 100%;
         text-align: left;
         padding-left: 1rem;
         padding-right: 1rem;
         text-transform: none;
         font-size: 1.2rem;
         font-weight: 300;
         margin: 0;
         position: relative;
         &:after {
            content: "\e900";
            font-family: oph !important;
            font-style: normal;
            font-weight: 400;
            float: right;
            transform: rotate(0deg);
            transition: all 0.4s;
            color: $color_brown_dark;
            font-size: 2rem;
         }
         &:hover, &:focus, &:active {
            background: none;
            border: 0;
         }
         &.collapsed {
            &:after {
               transform: rotate(180deg);
            }
         }
      }
   }

   .card-body {
      input[type='number'].form-control2 {
         -moz-appearance: number-input;
         border: 1px solid $color-gray;
         border-radius: 10px;
         padding-left: 10px;
         padding-right: 10px;
         display: inline-block;
         max-width: 80px;
      }
      .table {
         .code {
            white-space: nowrap;
         }
         .price {
            white-space: nowrap;
         }
      }
   }


}


.first_block_step4 {
   transition: all 0.3s;
   visibility: visible;
   opacity: 1;

   &.hide {
      visibility: hidden;
      opacity: 0;
      height: 0;
   }
}

.materiaux_liste_detail {
   visibility: hidden;
   opacity: 0;
   height: 0;
   transition: all 0.3s;

   &.show {
      visibility: visible;
      opacity: 1;
      height: 100%;
   }

   .block_title {
      .btn-back {
         font-size: 50px;
         margin-right: 1rem;
         vertical-align: middle;
      }
      span {
         font-size: 25px;
         font-weight: 500;
         color: $color_brown_dark;
      }
   }

   input[type='number'].form-control2 {
      -moz-appearance: number-input;
      border: 1px solid $color-gray;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      max-width: 80px;
   }
   .table {
      .code {
         white-space: nowrap;
      }
      .price {
         white-space: nowrap;
      }
   }
   .qte {
      width: 110px;
   }
   .actions {
      width: 50px;
      text-align: right;
      .remove_unit {
         background: none;
         border: 0;
         box-shadow: none;
         color: $color_yellow;
         font-size: 30px;
      }
   }

}