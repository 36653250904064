.tooltip-container {
  padding: 15px;
  font-size: 14px;

  border-radius: 5px;
  border: 1px solid $color_hr;
  background-color: $color_gray_dark;
  color: $color_white;

  @media (max-width: 991px ) {
    font-size: 14px;
  }

  a {
    color: $color_yellow;
  }
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background-color: $color_gray_dark;
  }

  &.bs-tooltip-bottom {
    &:after {
      top: -5px;
    }
  }
  &.bs-tooltip-top {
    &:after {
      bottom: -5px;
    }
  }
}
