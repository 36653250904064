// block Eligibilite
.block-eligibilite {
  background: rgba(110, 188, 233, 0.47);
  padding: 1rem;
  @include respond-above('sm') {
    padding: 2rem 5%;
  }
  @include respond-above('xl') {
    padding: 2rem 10%;
  }

  .bloc_image {
    align-items: center;
    padding-top: 1rem;
    @include respond-above('sm') {
      padding-top: 0;
    }

    h2 {
      margin-bottom: 30px;
      color: $color_blue_light;

      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      padding: 1rem 2rem;
      @include respond-above('sm') {
        font-size: 30px;
        font-weight: 700;
        line-height: 37px;
      }
      @include respond-above('md') {
        max-width: 310px;
        margin-bottom: 0;
      }
    }

    img {
      padding: 0;
      margin-bottom: 1rem;

      @include respond-above('sm') {
        padding: 0 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  .eligibilite_step {
    position: relative;

    @include respond-above('md') {
      top: -50px;
    }
    ul {
      list-style: none;
      counter-reset: item;
      padding: 0;
    }
  }

  .step_item {
    // background: $color_yellow;
    padding: 20px 30px 20px 60px;
    position: relative;
    @include respond-above('sm') {
      padding: 20px 20px 20px 100px;
    }
    @include respond-above('md') {
      padding: 30px 30px 30px 100px;
    }

    &:after {
      content: '';
      width: calc(100% - 40px);
      height: 1px;
      background: #f7f7f7;
      position: absolute;
      bottom: 0;
      right: 0;
      @include respond-below('md') {
        width: calc(100% - 20px);
      }
    }

    &:before {
      counter-increment: item;
      content: counter(item);
      position: absolute;
      left: 40px;
      font-size: 80px;
      top: 20px;
      line-height: 1;
      font-weight: 700;
      color: $color_white;
      @include respond-below('md') {
        left: 20px;
        font-size: 50px;
        top: 16px;
      }
    }

    &:first-child {
      background: #f7f7f7;
      margin-right: -15px;
      padding: 20px 100px 20px 60px;
      @include respond-above('md') {
        padding: 50px 100px 50px 100px;
      }
      &:before {
        color: $color_yellow;
        top: 40px;
        @include respond-below('md') {
          color: $color_yellow;
          top: 16px;
        }
      }
      &:hover {
        background: $color_brown_dark;
        color: $color_white;
        .title_step {
          color: $color_yellow;
          @include respond-below('md') {
            right: 25px;
          }
        }

        .link_step {
          text-decoration: none;
          color: $color_white;
          transform: translateX(5px);
          @include respond-below('md') {
            transform: translate(5px, -50%);
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .title_step {
      display: block;
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1.3;

      @include respond-below('md') {
        font-size: 16px;
      }
    }

    .info_step {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.2;

      @include respond-below('md') {
        font-size: 14px;
      }
    }

    .link_step {
      position: absolute;
      bottom: 40px;
      right: 40px;
      font-size: 40px;
      line-height: 1;
      text-decoration: none;
      transition: all 0.3s;
      color: $color_yellow;
      i {
        font-weight: bold;
      }
      @include respond-below('md') {
        bottom: unset;
        right: 30px;
        font-size: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .link_all {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

// Section Loger
.block-loger {
  .loger-item {
    text-align: center;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;

    img {
      display: block;
      width: 100%;
    }
    h3 {
      margin: 10px 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 0 15px;
      color: $color_black;
      font-size: 19px;
      font-weight: 500;
      line-height: 22px;
      height: 44px;

      @include respond-below('md') {
        height: auto;
      }

    }

    .description {
      display: block;
      padding: 0 15px 15px;
      height: 80px;
      color: $color_black;
      font-size: 15px;
      font-weight: 300;
      line-height: 25px;
      letter-spacing: 1px;
      overflow: hidden;

      @include respond-below('md') {
        height: auto;
      }

    }

    .btn-outline-primary {
      font-size: 11px;
      font-weight: 500;
      padding: 0.5rem 1.2rem;

      @include respond-above('md') {
        font-size: 12px;
      }
    }

    .image_link {
      overflow: hidden;
      display: block;
      width: 100%;

      img {
        transition: all 0.3s;
      }
    }

    &:hover,
    &:focus {
      border: 1px solid #f0f0f0;
      .image_link {
        img {
          transform: scale(1.05);
        }
      }

      .btn-outline-primary {
        color: $color_white;
        border-color: $color_brown_dark;
        background: $color_brown_dark;
      }
    }
  }
}

// block Bandeau
.block-bandeau {
  .image_content {
    position: relative;
    height: 280px;
    overflow: hidden;
    @include respond-above('sm') {
      height: 350px;
    }
    &:before {
      content: '';
      position: absolute;

      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      background-repeat: no-repeat;
      background-size: cover;
      background-image: var(--bg-image);

      @include respond-above('sm') {
        background-image: var(--bg-image);
        background-position-x: 50%;
      }
      @include respond-above('xl') {
        background-position-x: unset;
      }
    }

    img {
      //   width: 100%;
      // min-height: 350px;
      // position: absolute;
      // left: 50%;
      // transform: translate(-50%, 0);
    }

    .text-block-content {
      background: rgba(59, 39, 21, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 25px;
      text-align: center;
    }

    .text-content {
      h2 {
        color: $color_yellow;
        font-weight: 700;
        // font-size: 32px;

        font-size: 18px;
        @include respond-above('md') {
          font-size: 32px;
        }
      }

      p {
        color: $color_white;
        font-weight: 300;
        margin-bottom: 1rem;
        font-size: 13px;

        @include respond-above('md') {
          font-size: 21px;
        }
      }

      .btn-outline-secondary {
        font-size: 11px;
        padding: 0.5rem 1.5rem;
        border-width: 1px !important;

        @include respond-above('md') {
          font-size: 12px;
        }
      }
    }
  }
}

.block-document {
  .documents-list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;

    &__item {
      background-color: $color_gray_light;
      padding: 30px;
      color: $color_black;
      flex: 0 0 18%;
      max-width: 18%;
      display: flex;
      flex-direction: column;
      margin: 15px;

      @media (max-width: 1191px) {
        max-width: 25%;
        flex: 0 0 25%;
      }

      @media (max-width: 991px) {
        max-width: 33%;
        flex: 0 0 33%;
      }

      @media (max-width: 540px) {
        max-width: 40%;
        flex: 0 0 40%;
      }

      &:last-child {
        margin-right: 0;
      }
      .item-title {
        min-height: 100px;
        .title-icon {
          font-size: 30px;
        }
        .title-content {
          font-size: 15px;
          font-weight: 500;
        }
      }
      .item-content {
        margin-top: 25px;
        font-size: 35px;
        line-height: 35px;
        a {
          color: $color_yellow;
          padding: 0 5px;
        }
      }
    }
    .slick-slide {
      padding: 15px;
      .documents-list__item {
        max-width: 100%;
        flex: 0 0 100%;
        margin: 0;
      }
    }

  }
}
