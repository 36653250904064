:root {
   --oph-fond-jaune:#f9b233;
}
.select-profil {
   .section-title {
      margin-top: 0;
      margin-bottom: 0;
   }
   .primary-form {
      @media (min-width: 992px) {
         padding-right: 100px;
      }
   }
   .select-profil-block {
      background-color: #fff;
      padding: 50px 0 100px;
      height: auto;
      transition: 300ms ease-in-out;
      .section-title {
         margin-bottom: 0;
         line-height: 1.3;
         font-size: 25px;
      }
      @media (max-width: 991px) {
         padding: 50px 0;
      }
      @media (max-width: 767px) {
         padding: 30px 0;
      }
      &.hidden {
         padding: 0;
         height: 0;
         opacity: 0;
         visibility: hidden;
         .container.container-none {
            display: none;
         }
      }
      .item {
         padding-left: 30px;
         padding-right: 30px;
         @media (max-width: 991px) {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;
         }
         .btn-aid-item {
            font-size: 12px;
            margin-top: 30px;
            min-width: 140px;
            padding: 12px 15px;
            line-height: 1.2;
            @media (max-width: 991px) {
               padding: 13px;
               min-width: auto;
               width: 100%;
            }
            @media (max-width: 767px) {
               margin-top: 20px;
            }@media (max-width: 767px) {
               max-width: 120px;
            }
         }
      }
      .ratio-img {
         position: relative;
         .select-profil-block__img {
            max-width: 170px;

            @media (max-width: 767px) {
               max-width: 120px;
            }
         }
      }
   }
   .section-select-profil {
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
      height: 0;
      padding-bottom: 0;
      transition: 300ms ease-in-out;
      .container.container-none {
         display: none;
      }
      input[name="votre_mot_de_passe"] {
         font-family: "password";
      }
      &.show {
         z-index: auto;
         height: auto;
         opacity: 1;
         visibility: visible;
         padding-bottom: 100px;
         @media (max-width: 991px) {
            padding-bottom: 50px;
         }
         .container.container-none {
            display: block;
         }
         .back-to-select-profil {
            margin-top: 35px;
            opacity: 1;
            visibility: visible;

            @media (max-width: 1191px) {
               margin-top: 15px;
            }
         }
      }
      &__image {
         opacity: 0;
         visibility: hidden;
         height: 0;
         transition: 300ms ease-in-out;
         @media (max-width: 991px) {
            max-width: 150px;
         }
         @media (max-width: 767px) {
            max-width: 100px;
         }
         &.open {
            opacity: 1;
            visibility: visible;
            height: auto;
         }
      }
      .back-to-select-profil {
         opacity: 0;
         visibility: hidden;
         margin-bottom: 35px;
         transition: 300ms ease-in-out;

         @media (max-width: 1191px) {
            margin-bottom: 15px;
         }
         @media (max-width: 991px) {
            margin-bottom: 0;
         }

         .icon {
            font-size: 50px;
            @media (max-width: 767px) {
               font-size: 40px;
            }
         }
      }
      .primary-form {
         .section-title {
            font-size: 50px;
            font-weight: 300;
            margin-bottom: 50px;
            color: $color_blue_light;
            @media (max-width: 1191px) {
               font-size: 40px;
               margin-bottom: 30px;
            }
            @media (max-width: 991px) {
               margin-top: 10px;
               font-size: 30px;
            }
         }
      }
      .form-group-wrapper {
         + .form-group-wrapper {
            margin-top: 25px;
         }
      }
      .forget-password {
         a {
            font-size: 10px;
            font-weight: 300;
            color: #979797;
            display: block;
            margin-top: -10px;
            letter-spacing: 0.3px;
            @media (max-width: 991px) {
               margin-top: 20px;
            }
         }
         .action {
            float: right;
         }
      }
      .active-account {
         float: left;
      }
      .submit-form {
         margin-top: 50px;
         margin-bottom: 0;
         @media (max-width: 991px) {
            margin-top: 30px;
         }
         .btn {
            min-width: 170px;
            @media (max-width: 991px) {
               max-width: 200px;
               min-width: auto;
            }
         }
      }
   }
   @media (max-width: 991px) {
      .order-1 {
         order: 1;
      }

      .order-2 {
         order: 2;
      }

      .order-3 {
         order: 3;
      }
   }
}
#active_account_modal {
   .validate_account {
      padding: unset !important;
      .modal-body {
         max-width: unset !important;
         > .col-12 {
            margin-bottom: 1.5rem;
         }
      }
   }
   @media (min-width: 1400px) {
      .modal-dialog {
         max-width: 700px;
      }
   }
   &.recaptcha {
      position: relative;
      height: 0;
      padding: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border: none !important;
      #recaptcha_is_valid {
         position: absolute;
         width: 0;
         opacity: 0;
         visibility: hidden;
      }
      #recaptcha_is_valid-error {
         width: 100%;
         text-align: center;
         display: block;
      }
   }
}

#active_account_modal_demandeur{
   @extend #active_account_modal;
}

.body-update-password {
   background: white;
}

.locataire-account-activated {
   width: 35%;
}

.forgot-password-section {
   &.show {
      display: block;
   }
   .icon {
      font-size: 50px;
      @media (max-width: 414px) {
         font-size: 40px;
      }
   }
   .text.title {
      color: #7d4e24;
   }
}

.forgot-password-complete-section {
   &.show {
      display: block;
   }
   .icon {
      font-size: 50px;
      @media (max-width: 414px) {
         font-size: 40px;
      }
   }
   .text.title {
      color: #7d4e24;
   }
}

.update-password-section, .update-password-complete-section {
   .text.title {
      color: #7d4e24;
   }
   .error-message {
      &:not(.has) {
         .error{
            color: green;
         }
      }
   }
   @media (min-width: 992px) {
      .btn i {
         font-size: 16px;
      }
   }
}

.custom-authenticate-error-message {
   color: red;
}

.btn-student-period {
   text-transform: uppercase;
   display: block;
   text-align: center;
   padding: 0.75rem 0.5rem;
   font-size: 11px;
   font-weight: 600;
   line-height: 13px;
   @media (max-width: 991px) {
      margin: 0 auto !important;
   }
   @media (max-width: 767px) {
      margin: 0 auto !important;
   }@media (max-width: 767px) {
   margin: 0 auto !important;
      padding: 1rem;
   }
}
.modal-student-out-period {
   .modal-student-close {
      min-width: 1024px;
      min-height: 400px;
      background-color: var(--oph-fond-jaune);
      text-align: center;
      //padding: 5rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: red;
      @media (max-width: 991px) {
         min-width: 600px;
         min-height: auto;
         padding: 50px 0;
         margin: 2rem auto;
      }
      @media (max-width: 767px) {
         min-width: auto;
         min-height: auto;
         padding: 2rem;
         margin: 1rem auto;
      }

      .close {
         font-size: 40px;
         font-weight: 300;
         color: #3c2716;
      }
      .modal-body {
         padding: 4rem 0;
      }

      .modal-footer{
         padding: 1rem 2rem;
         @media (max-width: 767px) {
            padding: 0;
            justify-content: center;
         }
      }
   }
}