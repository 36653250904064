#header-push-fix {

  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  @media (max-width: 992px) {
    #alert-message {
      height: auto;
      display: block;

      .container {
        padding-top: 0;
      }
    }
  }
}