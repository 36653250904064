.locataire_factures, .agents_composition, .agents_profil, .agents_assurance {
  .block-content-tab {
    .title {
      .request_number_info_demande, .request_number_info_composition, .request_number_info_profil, .request_number_info_assurance {
        font-size: 25px;
        color: #6ebce9;
        font-weight: 300;
      }
    }
  }
}

.agents_reclamations {
  .block-content-tab {
    .title {
      margin-bottom: 50px;
      @include respond-below('md') {
        margin-bottom: 20px;
      }
      .request_number_info_demande, .request_number_info_reclamation, .request_number_info_profil, .request_number_info_composition {
        font-size: 25px;
        color: #6ebce9;
        font-weight: 300;
      }

    }
    table {
      .title {
        font-size: 15px;
        margin: 0;
      }
    }
  }
}
.block_filter {
  .filter_element {
    min-width: 170px;
    .select2-container {
      display: block;
    }
  }
  .filter_element2 {
    min-width: 270px;
    .select2-container {
      display: block;
    }
  }
}
.custom-form2 .form-group.bmd-form-group {
  margin-top: 10px;
  padding: 0;
}
.custom-form2 .form-group.bmd-form-group .select2-container {
  width: 100% !important;
}

.flex-grow-1 {
  flex-grow: 1;
}
.title_block {
  li {
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    padding: 5px 2rem 5px 0;
    color: $color_brown_light;
    margin-bottom: 0;
    a {
      color: $color_brown_light;
      &.active, &:hover, &:focus {
        color: $color_brown;
      }
    }

  }
}
.goback_block {
  font-size: 23px;
  font-weight: 500;
  .icon {
    font-size: 50px;
  }
}
.global_info_demande {
  li {
    display: inline-block;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid $color_brown;
    &:last-child {
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
    }
    strong {
      padding-right: 10px;
    }
    .icon {
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.validation_tab {
  #motif {
    .primary-form {
      padding: 120px 100px 0;
      @media (max-width: 991px) {
        padding: 60px 0 0;
      }
    }
    .step-submit {
      margin-bottom: 20px;
    }
  }

}

.uploaded_files_block {
  .list_files {
    li {
      display: inline-block;
      padding-right: 15px;
      font-weight: 400;
      font-size: 13px;
      a {
        border: 1px solid $color_yellow;
        color: $color_yellow;
        padding: 5px 10px;
        border-radius: 10px;

        &:hover, &:focus {
          border: 1px solid $color_brown;
          color: $color_brown;
        }

        i {
          padding-right: 5px;
        }
      }
    }
  }
}

.step_validate {
  font-size: 30px;
  padding-left: 15px;
  display: none;
  &:before {
    color: $color_green;
  }
  &.active {
    display: block;
  }
}

.primary-select2 {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  bottom: -95px;
  opacity: 0;
  visibility: hidden;
  transition: 300ms ease-in-out;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 27px solid transparent;
    border-right: 27px solid transparent;
    border-bottom: 20px solid #fff;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  &.open {
    bottom: -75px;
    opacity: 1;
    visibility: visible;
    z-index: 1;
    @media (max-width: 991px) {
      bottom: -90px;
    }
  }
  .select2-container {
    .selection {
      .select2-selection {
        border-color: transparent !important;
        @media (max-width: 991px) {
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          border-radius: 5px;
        }
      }
    }
    &.open {
      .selection {
        .select2-selection {
          @media (max-width: 991px) {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }
      }
    }
  }
}

.custom-toast {
  position: fixed;
  top: 30px;
  right: 30px;
  min-width: 150px;
  z-index: 11111;
  &.show {
    .toast {
      opacity:1 ;
    }
  }
}
#agent_demande_detail .select2-container--default.select2-container--disabled .select2-selection--single,
#agent_demande_detail .custom-file-control:disabled,
#agent_demande_detail .custom-file-control[readonly],
#agent_demande_detail .form-control:disabled, .form-control[readonly] {
  background-color: transparent;
}

#agent_reclamation_detail {
  .description {
    font-size: 15px !important;
    font-style: italic !important;
    font-weight: 300 !important;
    text-align: justify !important;
  }
  .contact_jour {
    font-size: 15px !important;
  }
  .libelle_jour {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
  .libelle_heure {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
  .contact_libelle {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
  .detail_jour {
    font-size: 15px !important;
  }
  .detail_heure {
    font-size: 15px !important;
  }
}

#reclamation_form_refuse, #profil_form_refuse, #composition_form_refuse, #assurance_form_refuse {
  .error-message {
    font-weight: 300;
    margin-top: -10px;
    .error {
      color: red;
      line-height: 1.3;
      font-size: 13px;
      margin-bottom: 0;
    }
  }
  .select2-selection__rendered {
    padding-top: 5px;
    opacity: 1 !important;
    .select2-selection__placeholder {
      opacity: 1 !important;
    }
  }
}

.uploaded_files_assurance_block {
  .list_files {
    li {
      display: block;
      font-weight: 400;
      font-size: 14px;
      a {
        background-color: $color_white;
        border: 1px solid $color_yellow;
        color: $color_black;
        padding: 12px 15px;
        border-radius: 10px;
        display: block;
        &:hover, &:focus {
          border: 1px solid $color_brown;
          color: $color_brown;
        }
        i {
          padding-right: 5px;
        }
      }
    }
  }
}

#composition_form_refuse, #assurance_form_refuse, #profil_form_refuse, #profil_form_refuse {
  .form-group {
    &.bmd-form-group {
      .select2-selection__rendered {
        &:after {
          display: none;
        }
      }
    }
  }
}
#composition_form_refuse, #assurance_form_refuse, #profil_form_refuse, #profil_form_refuse {
  .form-group {
    &.bmd-form-group {
      .select2-selection__rendered {
        &:after {
          display: none;
        }
      }
    }
  }
}