.custom-radio {
    text-transform: capitalize;
    $this: &;
    &__circle {
        width: 55px;
        height: 20px;
        background: $color-super-light-gray-04;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        transition: 300ms ease-in-out;
        &:before {
            content: '';
            position: absolute;
            display: block;
            height: 25px;
            width: 25px;
            background: #fff;
            border-radius: 100%;
            border: 1px solid $color-super-light-gray-04;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: 300ms ease-in-out;
        }
    }
    input[type=radio], input[type=checkbox]{
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer;
        &:checked {
            border: 10px solid #000;
            + #{$this}__circle {
                &:before {
                    left: auto;
                    right: 0;
                }
                &.green {
                    background: $color_yellow;
                    &:before {
                        border: 1px solid $color_brown;
                    }
                }
                &.purple {
                    background-color: $color_brown_light;
                    &:before {
                        border: 1px solid $color_gray_dark_02;
                    }
                }
            }
            &:disabled {
                + #{$this}__circle {
                    &.green {
                        background: $color_brown_light;
                        &:before {
                            background: #fff;
                            border: 1px solid $color_gray_dark_02;
                        }
                    }
                }
            }
        }
    }
}
