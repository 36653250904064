$xsBreakpoint: 767px;

.builder-element__title_paragraph_image {
  .builder-element__title_paragraph_image__edges {
    padding: 4rem 0;
  }

  &.builder-element__title_paragraph_image--grey2.builder-element__title_paragraph_image--with_image {
    background-color: #fff;

    .builder-element__title_paragraph_image__edges {
      background: linear-gradient(90deg, #FFFFFF 44%, #fafafa 56%);
    }
  }

  &.builder-element__title_paragraph_image--grey2.builder-element__title_paragraph_image--with_image_right {
    background-color: #fff;

    .builder-element__title_paragraph_image__edges {
      background: linear-gradient(90deg, #fafafa 56%, #FFFFFF 44%);
    }
  }

  .builder-element__title_paragraph_image__row {
    display: flex;
    align-items: center;
    @media (max-width: $xsBreakpoint) {
      flex-direction: column;
    }

    .builder-element__title_paragraph_image__row__body {
      line-height: 2.5rem;

      .builder-element__title_paragraph_image__row__body__title,
      .builder-element__title_paragraph_image__row__body__subtitle {
        text-align: center;
      }

      .builder-element__title_paragraph_image__row__body__title {
        margin-bottom: 3rem;

        &.builder-element__title_paragraph_image__row__body__title--with_subtitle {
          margin-bottom: 0;
        }
      }

      .builder-element__title_paragraph_image__row__body__subtitle {
        font-weight: 700;
        color: #7595a0;
        margin-bottom: 3rem;
      }
    }
  }

  &.builder-element__title_paragraph_image--with_image {
    .builder-element__title_paragraph_image__row {
      @media (max-width: $xsBreakpoint) {
        .builder-element__title_paragraph_image__row__image {
          width: 100%;
          margin: 1rem 0;
        }
      }
      @media (min-width: $xsBreakpoint) {
        .builder-element__title_paragraph_image__row__body {
          width: 45%;
          margin-left: 10%;
        }

        .builder-element__title_paragraph_image__row__image {
          width: 45%;
        }

        .builder-element__title_paragraph_image__row__body__title,
        .builder-element__title_paragraph_image__row__body__subtitle {
          text-align: left;
        }
      }
    }
  }

  &.builder-element__title_paragraph_image--with_image_right {
    .builder-element__title_paragraph_image__row {
      @media (min-width: $xsBreakpoint) {
        .builder-element__title_paragraph_image__row__body {
          margin-left: 0;
          margin-right: 10%;
        }
      }
    }
  }

  &.builder-element__title_paragraph_image--dark {
    background-color: #383838;
    padding: 5rem 0;

    .builder-element__title_paragraph_image__row {
      .builder-element__title_paragraph_image__row__body {
        color: #FFFFFF;
        text-align: center;

        @media (min-width: $xsBreakpoint) {
          .builder-element__title_paragraph_image__row__body__title,
          .builder-element__title_paragraph_image__row__body__subtitle {
            text-align: center;
          }
        }
      }
    }
  }

  &.builder-element__title_paragraph_image--background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 5rem 0;

    .builder-element__title_paragraph_image__row {
      .builder-element__title_paragraph_image__row__body {
        color: #FFFFFF;
        text-align: center;
      }
    }
  }

  &.builder-element__title_paragraph_image--grey2 {
    background-color: #fafafa;
    padding: 3rem 0;

    &.builder-element__title_paragraph_image--with_image {
      .builder-element__title_paragraph_image__row {
        align-items: center;
      }
    }

    .builder-element__title_paragraph_image__row {
      align-items: flex-start;

      @media (max-width: $xsBreakpoint) {
        .builder-element__title_paragraph_image__row__body {
          width: 100%;
        }
      }
      @media (min-width: $xsBreakpoint) {
        .builder-element__title_paragraph_image__row__body {
          .builder-element__title_paragraph_image__row__body__title,
          .builder-element__title_paragraph_image__row__body__subtitle {
            text-align: left;
          }

          &.builder-element__title_paragraph_image__row__body--aside {
            width: 55%;
            margin: 0 5%;
          }
        }
      }

      a {
        margin: 0;
      }
    }
  }
}

.builder-element__spacer {
  margin: 4rem 0;
}

.builder-element__blockquote {
  .builder-element__blockquote__item {
    .builder-element__blockquote__item__text {
      margin-bottom: 1rem;
    }
  }
}

.builder-element__3columns {
  .builder-element__3columns__title {
    text-align: center;
    margin-bottom: 3rem;

    &.builder-element__3columns__title--with_subtitle {
      margin-bottom: 0;
    }
  }

  .builder-element__3columns__subtitle {
    text-align: center;
    font-weight: 700;
    color: #7595a0;
    margin-bottom: 3rem;
  }

  .builder-element__3columns__row {
    display: flex;
    align-items: flex-start;
    @media (max-width: $xsBreakpoint) {
      flex-direction: column;
    }

    .builder-element__3columns__row__column {
      margin: 1.5%;
      width: 30%;
      @media (max-width: $xsBreakpoint) {
        width: auto;
        margin: 1rem;
      }

      .builder-element__3columns__title,
      .builder-element__3columns__subtitle {
        text-align: left;
        margin-bottom: 1rem;

        &.builder-element__3columns__title--with_subtitle {
          margin-bottom: 0;
        }
      }

      .builder-element__3columns__column__image {
        margin-bottom: 1rem;
        height: 350px;
        display: flex;
        align-items: center;
      }

      .builder-element__3columns__column__icon {
        i {
          display: block;
          font-size: 32px;
          line-height: 32px;
          margin-bottom: 1rem;
          color: #7595a0;
          transition: all 0.5s;
        }
      }
    }
  }
}
