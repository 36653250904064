@import '../variables';
@import '../mixins';

.appels-doffres {
  .section-header {
    @include respond-below('md') {
      // padding-left: 17px;
      // padding-right: 17px;

      hr {
        display: none;
      }
    }
    .btn-applier {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      left: 50%;
      transform: translate(-50%, -59%);
      .btn {
        line-height: 13px;
        padding: 0.75rem 3.5rem !important;
      }
    }
    hr {
      margin-top: 0;
    }

    @media (min-width: 769px) {
      .filter-btn,
      .filter-mobile {
        opacity: 0;
        visibility: hidden;
        height: 0;
      }
    }
    .filter-mobile-section {
      @include respond-below('md') {
        display: flex;
        align-items: center;
        padding: 15px;
      }
    }
    @include respond-below('md') {
      .filter-btn {
        margin-left: auto;
        order: 2;
        font-size: 25px;
        color: $color_yellow;
        i {
          font-size: 35px;
        }
      }
      .filter-btn,
      .filter-mobile {
        opacity: 1;
        visibility: visible;
        height: auto;
      }
      .appels-filter {
        display: none;
      }
      .side-select {
        position: fixed;
        top: 0;
        left: 100%;
        right: 0;
        bottom: 0;
        transition: left 0.5s ease-in-out;
        z-index: 2000;
        width: 100%;
        height: 100%;
        background-color: $color_white;
        padding: 40px 20px;
        &__title {
          padding-bottom: 20px;
          border-bottom: 1px solid $color_gray_light_03;
          .icon-close {
            position: absolute;
            top: 38px;
            right: 20px;
            font-size: 24px;
          }
          h2 {
            line-height: 22px;
          }
          h4 {
            line-height: 17px;
          }
          .dropdown-menu__item {
            font-size: 13px;
            letter-spacing: 1;
            text-decoration: underline;
            font-weight: 300;
            line-height: 23px;
          }
        }
      }
      .filter-mobile {
        &.show {
          left: 0;
        }
        .has-dropdown {
          display: flex;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px solid $color_gray_light_03;
        }
        .oph-form {
          &__label {
            font-size: 13px;
            color: $color_brown_dark;
            font-weight: 700;
            line-height: 23px;
            @media (max-width: 320px) {
              font-size: 11px;
            }
          }
          &__input {
            background-color: $color_white;
            border: none;
            color: $color-black;
            appearance: none;
            transition: border-color 200ms ease-in-out;
            text-align: right;
            &:focus {
              box-shadow: none;
              border-color: unset;
              outline: none;
            }
          }
          @include element('dropdown') {
            margin-left: auto;
            input {
              &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $color_gray_light_01;
                opacity: 1; /* Firefox */
                font-weight: 300;
                letter-spacing: 1;
              }
            }
            .dropdown-select {
              position: fixed;
              top: 0;
              left: 100%;
              right: 0;
              bottom: 0;
              transition: left 0.5s ease-in-out;
              z-index: 2000;
              width: 100%;
              height: 100%;
              background-color: $color_white;
              padding: 40px 20px;
              &.show {
                left: 0;
              }
              .side-select {
                &__title {
                  color: $color_brown_dark;
                  line-height: 1.6;
                  i {
                    font-size: 33px;
                  }
                }
              }
              .oph-dropdown-menu {
                .dropdown-menu__item {
                  padding: 20px 10px;
                  border-bottom: 1px solid $color_gray_light_03;
                  i {
                    float: right;
                    display: none;
                  }
                  &:hover {
                    i {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .oph-form {
    .form-group {
      margin-top: 0;
    }
    .select2-results__option {
      font-weight: 300;
      font-size: 13px;
      &:first-child {
        display: none;
      }
    }
    .select2-container {
      &--default {
        .select2-search--dropdown {
          padding: 10px 5px 5px;
          .select2-search {
            &__field {
              outline: 0;
              border-radius: 5px;
              padding: 8px;
            }
          }
        }
      }
    }
  }

  .appels-list {
    padding: 0 1rem;
    // @include respond-above("sm") {
    //    padding: 1rem;
    // }
    @include respond-above('md') {
      padding: 0 2rem;
    }
    @include respond-above('lg') {
      padding: 0 3rem;
    }
    @include respond-above('xl') {
      padding: 0 4rem;
    }
    @include respond-above('xxl') {
      padding: 0 10rem;
    }

    @include element('item') {
      background: $color_white;
      padding: 0 50px 10px 30px;
      // transition: 0.3s;
      transition: opacity 1s;

      &:not(:first-child) {
        margin: 20px 0;
      }
      @include respond-below('lg') {
        padding: 0 30px 10px 30px;
      }
      @media (max-width: 320px) {
        padding: 0 15px 20px;
      }
      &.show {
        opacity: 1;
        visibility: visible;
        height: auto;
      }
      &.hide {
        opacity: 0;
        visibility: hidden;
        height: 0;
        margin: 0;
        padding: 0;
      }
      .type-tag {
        display: inline-block;
        background-color: $color_blue_light;
        color: white;
        text-transform: uppercase;
        font-weight: 500;
        padding: 1px 15px;
      }
      .title {
        @include respond-above('xs') {
          br {
            display: none;
          }
        }
      }
      .item-content {
        @include element('info') {
          flex: 0 0 50%;
          i {
            font-size: 18px;
          }
          .status-tag {
            border-radius: 16px;
            background: $color_gray_light;
            font-size: 11px;
            font-weight: 300;
            line-height: 22px;
            padding: 2px 10px;
            margin-right: 10px;
            white-space: nowrap;
            &:hover {
              background: $color_blue_light;
              color: white;
            }
            @include respond-below('md') {
              display: inline-block;
              margin: 5px 0;
            }
          }
          @include respond-below('md') {
            .date {
              margin-top: 10px;
            }
          }
          .date,
          .destination {
            white-space: nowrap;
          }
        }
        @include element('group-btn') {
          flex: 0 0 50%;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          @media (max-width: 1025px) {
            justify-content: center;
          }
          @include respond-below('md') {
            justify-content: space-around;
            padding-top: 20px;
            border-top: 1px solid $color_gray_light_02;
          }
          .btn-item {
            text-align: center;
            &:not(:first-child) {
              margin-left: 50px;
              @include respond-below('md') {
                margin-left: 0;
                min-width: 55px;
              }
            }
            &.disabled {
              a {
                pointer-events: none;
                cursor: pointer;
                background: $color_gray_dark_02;
                color: $color_gray_light_01;
                border: transparent;
              }
              p {
                color: $color_gray_light_04;
                @include respond-below('md') {
                  &.hide-mobile {
                    display: none;
                  }
                }
              }
              @include respond-below('md') {
                display: none;
              }
            }
            .btn-icon {
              display: inline-block;
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 1px solid $color_brown_dark;
              color: $color_brown_dark;
              font-size: 25px;
              i {
                line-height: 45px;
              }
              &:hover {
                background: $color_brown_dark;
                color: $color_white;
              }
              @include respond-below('md') {
                width: 33px;
                height: 33px;
                font-size: 20px;
                i {
                  line-height: 33px;
                }
              }
            }
            p {
              color: $color_brown_dark;
              text-transform: uppercase;
              font-size: 8px;
              font-weight: 500;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .pagination {
    margin: 20px auto 30px;
    justify-content: center;
    .page-item {
      .page-link {
        color: $color_brown;
        font-size: 14px;
        font-weight: 300;
        &:focus {
          outline: none;
          box-shadow: none;
          font-weight: 700;
        }
      }
      &.active {
        .page-link {
          background-color: transparent;
          border: none;
          font-weight: 700;
        }
      }
    }
  }
  .paginationjs {
    display: flex;
    justify-content: center;
    margin: 2rem auto 4rem;
    li {
      border: none !important;
      background: transparent;
      > a {
        background: transparent;
        font-size: 12px;
        color: $color_brown;
      }
      &.active {
        > a {
          background: transparent;
          font-weight: 700;
          color: $color_brown;
        }
      }
    }
  }
}
