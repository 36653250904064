.module-cesure-bleur {
  background-color: rgba(110, 188, 233, 0.22);
  overflow: hidden;
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 2rem 15px 8rem;
  @include respond-above('md') {
    background-image: url('../../images/oval-bg.png');
    background-color: unset;
    padding: 3rem 3rem 8rem;
  }
  @include respond-above('lg') {
    padding: 5rem 3rem 10rem;
  }
  @include respond-above('xxl') {
    background-position-y: -200px;
  }
  .pattern-blur {
    position: absolute;
    z-index: 19;
    right: -5%;
    top: -35%;
    width: 756px;
    height: 448px;
    transform: rotate(-162deg);
    opacity: 0.074827;
    img {
      @include respond-above('md') {
        height: 100%;
      }
    }
    @include respond-below('md') {
      display: none;
    }
  }
  @include respond-below('md') {
    border-radius: unset;
  }
  @include respond-below('xs') {
    padding: 2.5rem 0 4rem;
    min-height: auto;
  }
}

.metiers-detail-block {
  margin: 50px 0;
  @include respond-below('md') {
    br {
      display: none;
    }
  }
  .profession-block {
    @include element('title') {
      font-style: italic;
    }
    @include element('content') {
      margin-top: 30px;
      line-height: 1.6;
      text-align: justify;
      max-width: 500px;
      letter-spacing: 1px;
      @include respond-below('lg') {
        font-size: 14px;
        max-width: none;
      }
    }
  }
  .mission-block {
    @include element('list') {
      margin-top: 37px;
      border-left: 1px solid $color_blue_light;
      max-height: 510px;
      overflow-y: auto;
      @include respond-below('md') {
        border-left: unset;
        margin-top: 0;
      }
      .mission-item {
        padding-top: 20px;
        @include element('content') {
          padding-bottom: 20px;
          border-bottom: 1px solid $color_blue_light;
          letter-spacing: 1px;
        }
      }
    }
  }
}
.content-block {
  .right {
    > div {
      padding-left: 75px;
      @include respond-below('lg') {
        padding-left: 50px;
      }
    }
    @include respond-below('md') {
      margin-top: 35px;
      > div {
        padding-left: 0;
      }
    }
  }
}

.competences-section {
  @include respond-below('md') {
    > div {
      text-align: center;
    }
    .competence-block,
    .formations-block {
      padding: 0 20px;
    }
    .formations-block {
      br {
        display: none;
      }
    }
  }
  @media (max-width: 320px) {
    .competence-block,
    .formations-block {
      padding: 0;
    }
  }
  // @include respond-below("xs") {
  //    .competence-block,
  //    .formations-block {
  //       max-width: 260px;
  //    }
  // }

  .competence-block {
    @include element('content') {
      margin-top: 20px;
      white-space: nowrap;
      ul {
        font-weight: 500;
        &:nth-child(2) {
          margin: 0 50px;
          @include respond-below('xs') {
            margin: 0 25px;
          }
        }
      }
    }
  }
  .formations-block {
    @include element('content') {
      margin-top: 35px;
      text-align: justify;
      line-height: 22px;
      max-width: 500px;
      letter-spacing: 1px;
      @include respond-below('lg') {
        font-size: 14px;
        max-width: none;
      }
    }
  }
}

.temoignage-section {
  margin: 50px 0;

  @include element('title') {
    align-items: flex-start !important;
    i {
      font-size: 39px !important;
      @include respond-below('md') {
        font-size: 30px !important;
      }
    }
  }
  @include element('list') {
    margin-top: 35px;
    .quote-item {
      margin-bottom: 20px;
      @include element('img') {
        max-width: 160px;
      }
      @include element('content') {
        font-style: italic;
        padding-left: 25px;
        letter-spacing: 1px;
        text-align: left;
        margin-top: 15px;
      }
      @include respond-below('md') {
        flex-wrap: wrap;
        justify-content: center;
        @include element('content') {
          padding: 0 15px;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.metiers-details {
  .title-icon {
    i {
      font-size: 30px;
      color: $color_blue_light;
      padding-right: 15px;
      @include respond-below('xs') {
        font-size: 25px;
      }
    }
  }
  ul {
    li {
      margin-left: 1rem;

      font-size: 13px;
      font-weight: 300;
      line-height: 30px;
      color: $color_black;
      @include respond-above('md') {
        line-height: 38px;
        font-size: 15px;
      }
      &:before {
        content: '\2022';
        color: $color_blue_light;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}
