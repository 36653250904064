.ohp-paragraph {
  .place-title {
    // font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: $color_gray_dark;

    font-size: 20px;
    font-weight: 300;
    @include respond-above('md') {
      font-size: 50px;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 300;
    color: $color_brown_dark;
    @include respond-above('md') {
      font-size: 30px;
    }
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    color: $color_brown_dark;

    @include respond-above('md') {
      font-size: 25px;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 300;
    color: $color_blue_light;
    padding-bottom: 5px;
    border-bottom: solid 1px $color_blue_light;
    @include respond-above('md') {
      font-size: 25px;
    }
  }

  h6 {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color_brown_dark;
    @include respond-above('md') {
      font-size: 15px;
      line-height: 23px;
    }
  }

  .description,
  div.description p {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    color: $color_blue;
    text-align: justify;
    @include respond-above('md') {
      font-size: 18px;
    }
  }

  p {
    font-size: 13px;
    font-weight: 300;
    color: $color_black;
    text-align: justify;
    @include respond-above('md') {
      font-size: 15px;
    }
  }
  img {
    width: 100%;
  }

  ul {
    li {
      margin-left: 1rem;

      font-size: 13px;
      padding: 10px 0;
      font-weight: 300;
      line-height: 1.4;
      color: $color_black;
      @include respond-above('md') {
        font-size: 15px;
      }
      &:before {
        content: '\2022';
        color: $color_blue_light;
        // font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  .title-icon {
    i {
      font-size: 30px;
      color: $color_blue_light;
      padding-right: 15px;
      @include respond-below('xs') {
        font-size: 25px;
      }
    }
  }
}
