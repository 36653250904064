.nav-pagination {
  line-height: 1.6;
  font-family: Marmelad,"Lucida Grande",Arial,"Hiragino Sans GB",Georgia,sans-serif;
  font-size: 14px;
  box-sizing: initial;
  margin: 2rem auto 4rem;

  ul {
    float: left;
    margin: 0;
    padding: 0;
  }
  li {
    border: none !important;
    background: transparent;
    float: left;
    list-style: none;
  }
  li > a {
    min-width: 30px;
    height: 28px;
    display: block;
    background: transparent;
    font-size: 12px;
    color: #7d4e24;
    text-decoration: none;
    text-align: center;
    border-radius: 0 !important;
  }
  li > a:hover {
     background: #eee;
    border-radius: 0 !important;
    color: #7d4e24;
  }
  li.disabled > a {
    opacity: .3;
  }
  li.active > span {
    height: 30px;
    background: transparent !important;
    font-weight: 700;
    color: #7d4e24 !important;
    border: none !important;
    min-width: 12px;
    margin-right: 0;
    font-size: 12px;
  }
}