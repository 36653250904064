@import '~bootstrap4/dist/css/bootstrap.css';
@import '../concept-theme/assets/fonts/mt-icons/mt-icons.css';
@import '~animate.css/animate.css';
@import "datepicker/jquery-ui.css";
//@import '../concept-theme/assets/css/custom.css';
//@import '../concept-theme/assets/css/pages-style.css';
@import '~bootstrap-material-design/dist/css/bootstrap-material-design.min.css';
@import './builder';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
@import '_variables.scss';
@import 'fonts';
@import 'icons';
@import 'mixins.scss';
@import 'animations';
@import '_core.scss';

@import "components/header.scss";
@import "components/footer.scss";
@import "components/buttons.scss";
@import "components/input.scss";
@import "components/banner.scss";
@import "components/tabs.scss";
@import "components/select2.scss";
@import "components/section-header.scss";
@import "components/popup-modal.scss";
@import "components/tooltip.scss";
@import "components/table.scss";
@import "components/paragraph.scss";
@import "components/block.scss";
@import "components/pagination.scss";
@import "components/radio.scss";
@import "components/toolbar.scss";
@import "components/search.scss";

@import 'elements/text.scss';
@import 'pages/homepage.scss';
@import 'pages/notre_histoire.scss';
@import 'pages/metiers_details.scss';
@import 'pages/lotissements.scss';
@import 'pages/appels_offre';
@import 'pages/postuler.scss';
@import 'pages/connexion.scss';
@import 'pages/demandeur.scss';
@import 'pages/demandeur_step_2.scss';
@import 'pages/agents_demandes.scss';
@import 'pages/simulation.scss';
@import 'pages/demande_aahi.scss';

@import 'pages/locataire_profil.scss';
@import 'pages/locataire_reclamations.scss';
@import 'pages/locataire_documents.scss';
@import 'pages/locataires.scss';

@import 'pages/prestataire_documents.scss';


@import 'pages/components.scss';
@import 'pages/borne.scss';

//#page-content {
//  padding-bottom: 2rem;
//}

#logo {
  margin: 0;
  height: 120px;

  img {
    height: 120px;
  }
}

a.empty-link{
  cursor: default;
}

a.submenu::before {
  content: ">";
}

a.submenu {
  margin-left: 10px;
}

.alert:after {
  content: '';
}

.image-box {
  margin: 1rem;
  text-align: center;
  min-height: auto;

  img {
    width: 100%;
    max-width: 600px;
  }
}

.g-recaptcha {
  clear: both;
}

#page-header {
  margin-bottom: 50px;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  padding: 1rem;
  width: 100%;
  z-index: 1;

  .alert {
    background: #FFF;
    margin: 0;
  }
}

.images-slider {
  margin-bottom: 0;
}

.images-slider .owl-nav {
  position: absolute;
  z-index: 555;
  bottom: 0;
  left: 0;
  padding: 20px 50px;
  background-color: #fff;
}

.images-slider .owl-nav span {
  display: none;
}

.owl-carousel {
  margin-bottom: 2rem;
}

.owl-prev {
  left: 10px;
}

.owl-next {
  right: 10px;
}

a.fancybox {
  cursor: zoom-in;
}

#footer-container {
  padding: 3rem 0;
}

// --- RGPD --- //
:root {
  --primary-color: #000;
  --secondary-color: #888;
  --checked-button-color: #007bff;
  --disabled-button-color: #aaa;
  --unchecked-button-color: #aaa;
  --unchecked-button-before-color: #777;
}

.mt-20{ margin-top: 20px; }

.modal-backdrop { z-index: 7; }

#rgpd_modal{ z-index: 1050; }

.cookie_banner {
  width: 95%;
  position: fixed;
  visibility: hidden;
  bottom: 0;
  background: #fff;
  color: #fff;
  transition: bottom 5s;
  z-index: 5;
  padding: 10px 40px;
  margin: 0 3%;}

.cookie_banner.active {
  visibility: visible;
}

#cookie_modal .modal-footer .modal-button-container{
  text-align: center;
}

#cookie_modal .btn {
  font-weight: 700;
  font-size: 0.9em;
  text-transform: none;
  @media (max-width: 425px) {
    font-weight: inherit;
    font-size: 0.8em;
  }
}

#cookie_modal .cookie-modal-link {
  text-decoration: underline;
  font-size: 1.05em;
  @media (max-width: 425px) {
    font-size: 0.9em;
  }
}

#cookie_modal .btn-default {
  color: #fff;
  background: #888;
}

#cookie_modal .modal-dialog {
  max-width: 700px;
  margin: 15% auto;
}

#cookie_modal .modal-content {
  padding: 10px;
}

.rgpd_actions{
  height: fit-content;
}

.close_rgpd_box {
  color: #fff;
  background: none;
  border: 0;
  position: absolute;
  right: 7px;
  top: 7px;
}

#cookie_modal .rgpd-modal-text {
  color: var(--primary-color) !important;
  margin-top: 15px;
}

#rgpd_modal .modal-body {
  font-size: 13px;
  max-height: 500px;
  overflow-y: auto;
}
#rgpd_modal .modal-footer {
  border-top: 0;
}
#rgpd_modal .modal-header {
  padding: 1rem 2rem;
  justify-content: space-between;
  color: var(--primary-color) !important;
}
@media (min-width: 992px) {
  #rgpd_modal .modal-header {
    padding: 1rem;}
}
#rgpd_modal .modal-body {
  padding: 2rem;
}
@media (min-width: 992px) {
  #rgpd_modal .modal-body {
    padding: 1rem;
  }
}
#rgpd_modal .modal-body {
  font-size: 13px;
  padding: 2rem;
}
@media (min-width: 992px) {
  #rgpd_modal .modal-body {
    padding: 1rem;
  }
}
#rgpd_modal .modal-footer {
  border-top: 0;
  padding: 2rem;
}
@media (min-width: 992px) {
  #rgpd_modal .modal-footer {
    padding: 1rem 1rem 2rem 1rem;
  }
}
#rgpd_modal .modal-footer .row {
  margin-left: -15px;
  margin-right: -15px;
}
#rgpd_modal .close-button {
  transition: 400ms;
  cursor: pointer;
  margin-top: 5px;
}
@media (min-width: 767px) {
  #rgpd_modal .close-button {
    top: 20px;
    right: 15px;
  }
}
#rgpd_modal .close-button:hover {
  transform: rotate(180deg);
}

.rgpd_items .icon_item {
  font-size: 25px;
  flex-basis: 20%;
  text-align: center;
  color: var(--primary-color) !important;
}
.rgpd_items .description_item {
  padding: 0 15px;
  flex-basis: 60%;
  color: var(--primary-color) !important;
}
.rgpd_items .checkbox_item {
  flex-basis: 20%;
}
.rgpd_items .disabled .icon_item {
  color: var(--secondary-color) !important;
}
.rgpd_items .disabled .description_item {
  color: var(--secondary-color) !important;
}

.cookie_banner .btn-dark:hover, .modal .btn-dark:hover{
  background: #fff;
  color: var(--primary-color) !important;
}

.modal-footer a {
  color: var(--primary-color) !important;
  font-size: 14px;
}

/* =========== checkbox =========== */

/* The text--semi-bold - the box around the custom-radio__circle */
.text--semi-bold {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 20px;
}

/* Hide default HTML checkbox */
.text--semi-bold input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The custom-radio__circle */
.custom-radio__circle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--unchecked-button-color) !important;
  -webkit-transition: .4s;
  transition: .4s;
}

.custom-radio__circle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0;
  bottom: -3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border: solid 1px var(--unchecked-button-before-color) !important;
}

input:checked + .custom-radio__circle {
  background-color: var(--checked-button-color) !important;
  opacity: 0.6;
}

input:focus + .custom-radio__circle {
  box-shadow: 0 0 1px var(--unchecked-button-before-color) !important;
}

input:checked + .custom-radio__circle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(29px);
  border: solid 1px #212529;
}

/* Rounded custom-radio__circles */
.custom-radio__circle.d-block {
  border-radius: 34px;
}

.custom-radio__circle.d-block:before {
  border-radius: 50%;
}

/*=========== Disabled checkbox ===========*/
.custom-radio input[type=checkbox]:checked:disabled+.custom-radio__circle.d-block {
  background: var(--disabled-button-color) !important;}
.custom-radio input[type=checkbox]:checked:disabled+.custom-radio__circle.d-block:before {
  border: 1px solid #e0e0e0;
}
.custom-radio input[type=radio]:checked + .custom-radio__circle:before, .custom-radio input[type=checkbox]:checked + .custom-radio__circle:before {
  right: auto;
  top: -3px;
}
/* Link */
.rgpd-link:hover {
  text-decoration: underline;
}
// --- RGPD --- //
.modal-backdrop {
  &.show {
    z-index: 1049;
    opacity: .7;
  }
}


/***********************************************************************************
 *	+ SCROLL UP
 ***********************************************************************************/

#scroll-up {
  z-index: 8000;
  display: none;
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 45px;
  height: 45px;
  font-size: 20px;
  line-height: 43px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  background-color: $color_yellow;
  border: 1px solid $color_brown;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#scroll-up:hover {
  background-color: $color_brown;
  color: $color_black;
}

.op-10{
  opacity: 0.1 !important;
}

.form-group i {
  position: absolute;
}

i#tooglePassword_old,
i#tooglePassword_new,
i#tooglePassword_confirm,
i#tooglePassword_new_pass,
i#tooglePassword_show_pass,
i#tooglePassword_confirm_pass,
i#tooglePassword_renew,
i#tooglePassword_renew_confirm, {
  display: flex;
  align-items: center;
  right: 10px;
  color: #c0c0c0;
}

/*--------- Activation modal ---------*/
.modal-dialog .modal-content .modal-body .modal-body{
  max-height: 100%;
}

@media (max-width: 767px){
  body.padding-120 {
    padding-top: 120px;
  }
}

.card-compte-tiers{
  box-shadow: 0 1px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  p{
    font-size: 15px;
  }
  a{
    font-weight: bold;
  }
}

.ml-0{
  margin-left: 0 !important;
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
  background-color: white;
}

#mydesk {
  display: none !important;
}
#bt-webcall {
  position: fixed;
  top: 20%;
  right: 0;
  border-radius: 30px;
  background: #f9b233;
  display: flex;
  padding: 5px 15px;
  .picto {
    padding-right: 5px;
    #phone_picto {
      width: 35px;
    }
    .active {
      display: inline-block;
    }
    .inactive {
      display: none;
    }
  }
  .etre-rappele {
    display: flex;
    align-items: center;
  }
}