.demandeur-step-contact {
   margin-bottom: 4rem;
   @include respond-below("xs") {
      margin-bottom: 3rem;
   }
   .demandeur-step-title {
      margin-bottom: 3rem;
      @include respond-below("xs") {
         margin-bottom: 1.5rem;
      }
   }
   .btn-icon {
      // margin-top: 10px;
      i {
         font-size: 45px;
         color: $color_gray_light_01;
      }
      &:hover {
         i {
            color: $color_blue_light;
         }
      }
      .icon-close-border {
         color: $color_yellow;
      }
   }
   .form-group {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
         -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      .bmd-label-floating {
         text-transform: capitalize;
      }
      &.autocomplete {
         position: relative;
         // display: inline-block;
         .autocomplete-items {
            position: absolute;
            border: 1px solid $color_gray_light_01;
            border-bottom: none;
            border-top: none;
            z-index: 9999;
            /*position the autocomplete items to be the same width as the container:*/
            top: 100%;
            left: 0;
            right: 0;
            font-size: 14px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: #fff;
            .island-option {
               padding: 10px;
               cursor: pointer;
               // background-color: #fff;
               &:hover {
                  background-color: $color_yellow;
                  color: #fff;
               }
            }
         }
         .autocomplete-active {
            /*when navigating through the items using the arrow keys:*/
            background-color: DodgerBlue !important;
            color: #ffffff;
         }
      }
   }
   .contact-list__item {
      + .contact-list__item {
         @media (min-width: 1200px) {
            padding-right: 25px;
         }
         &:nth-child(2) {
            margin-left: 0;
         }
      }
      .contact-input {
         flex: 0 0 90%;
         @include respond-below("md") {
            flex: 0 85%;
         }
         .form-group.bmd-form-group {
            max-width: 275px;
            @media (max-width: 1399px) {
               max-width: 198px;
            }
            @media (max-width: 1199px) {
               max-width: 100%;
            }
         }
      }
      .contact-group-btn {
         margin-top: 11px;
         margin-left: 8px;
         flex: 0 0 10%;
         display: flex;
         align-items: flex-start;
         @include respond-below("md") {
            flex: 0 15%;
         }
      }
      &.full-width {
         .contact-input {
            flex: 0 0 100%;
         }
         .contact-group-btn {
            flex: 0 0 0%;
         }
      }
   }

   .cus-col-40 {
      @media (min-width: 768px) {
         flex: 0 0 40%;
         max-width: 40%;
      }
   }
   .cus-col-30 {
      @media (min-width: 768px) {
         flex: 0 0 30%;
         max-width: 30%;
      }
   }
}
#formTopPosition input:disabled {
   background-color: transparent;
}
.step-section {
   .info-block {
      border-top: 1px solid $color_blue_light;
      padding: 10px 0;
      margin-bottom: 30px;
      .title {
         color: $color_blue_light;
         font-weight: 500;
         font-size: 15px;
      }
   }
   .fit-picture {
      border-radius: 50%;
      width: 150px;
      height: 150px;

   }
   @media (min-width: 1900px) {
      .upload-profile-photo .row {
      justify-content: center !important;
      }
   }

   .section-cursus {
      border-top: 1px solid #6ebce9;
      .title {
         color: #6ebce9;
         font-weight: 500;
         font-size: 15px;
      }
      .school-studied-information i.icon-tooltip{
         margin-bottom: -5px;
      }
      .school-studied-year-info i.icon-tooltip{
         margin-bottom: -5px;
      }
      .studied-followed-info i.icon-tooltip{
         margin-bottom: -8px;
      }
      .studied-followed-year-info i.icon-tooltip{
         margin-bottom: -8px;
      }
   }

   .student-upload-cps{
      padding-top: 1.3rem!important;
   }

   .section-childs-numbers {
      padding-top: 2rem;
      border-top: 1px solid #6ebce9;
   }

   @media screen and ( max-width: 1200px ) {
      .profilePhoto {
         display: flex;
         flex-direction: column-reverse;
      }
   }

}
