// Reboot
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  letter-spacing: 1px;
}

a {
  color: #3b2715;

  &:hover {
    color: #85b8e7;
  }
}

body {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: $color_brown_dark;
  background-color: $color_white;

  @include respond-below('sm') {
    padding-top: $header_height_mobile;
  }
}

p {
  font-weight: 300;
}

img {
  width: auto;
  max-width: 100%;
}

li {
  list-style: none;
}

p,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

a {
  cursor: pointer;
  transition: 200ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: $color_yellow;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

.oph-img {
  width: 100%;
  height: auto;
  transition: 300ms ease-in-out;
  backface-visibility: hidden;
}

.navbar {
  box-shadow: none;
}

.bg-white {
  background: #fff;
}

.bg-gray-light {
  background: $color_gray_light;
}

.bg-black {
  background: $color_black;
}
.bg-black-light {
  background: $color_black_light;
}
.cl-white {
  color: #fff;
}

.cl-blue-light {
  color: $color_blue_light;
}
.cl-black {
  color: $color_black !important;
}
.cl-yellow {
  color: $color_yellow;
}

.cl-gray {
  color: $color-gray;
}
.cl-gray-light-02 {
  color: $color_gray_light_02;
}

.cl-brown {
  color: $color_brown;
}

.cl-brown-dark {
  color: $color_brown_dark;
}

hr {
  border-top: 1px solid $color_hr;
}

// Hide input number arrow
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Override container */
.container {
  @include respond-above('xl') {
    max-width: 1300px;
  }
}

.tooltip {
  z-index: $tooltip_index;
}

.custom-file-control,
.form-control,
.is-focused .custom-file-control,
.is-focused .form-control {
  background-image: linear-gradient(
    0deg,
    $color_yellow 2px,
    rgba(0, 150, 136, 0) 0
  );
}

@media (max-width: 991px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.45rem;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.05rem;
  }
}