@import '../variables';
@import '../mixins';

.simulation-page {
  .modal-dialog .modal-content .modal-body .custom-form .form-group.has-error {
    margin-bottom: 2.5rem;
  }
  .d-condition {
    display: none;
  }
  .section-attention {
    // padding: 2rem;
    background-color: $color_white;
    @include respond-below('sm') {
      display: none;
    }
    // @include respond-above('md') {
    //   padding: 3rem;
    // }
    // @include respond-above('lg') {
    //   padding: 5rem 3rem;
    // }
    h4 {
      font-size: 15px;
      font-weight: 700;
      line-height: 23px;
      text-transform: uppercase;
      color: $color_brown;
    }
    .content {
      font-size: 14px;
      font-weight: 300;
      color: $color_black;
      b {
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        padding-top: 2px;
        display: block;
      }
      i {
        font-size: 50px;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        flex: 1 0 50%;
        padding-top: 2px;
      }
    }
  }
  .section-aid {
    background-image: url('../../images/oval-bg-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem 15px 8rem;

    @include respond-above('sm') {
      background-image: url('../../images/oval-bg.png');
      padding: 2rem 15px 6rem;
    }

    @include respond-above('md') {
      padding: 3rem 3rem 8rem;
    }
    @include respond-above('lg') {
      padding: 4rem 3rem 8rem;
    }
    @include respond-above('xxl') {
      background-position-y: -100px;
    }
    .aid-item {
      text-align: center;
      &.logement {
        //opacity: .5;
        //pointer-events: none;

      }
      .aid-image {
        padding: 0;
        margin-bottom: 0.5rem;
        @include respond-above('md') {
          padding: 0 2rem;
        }
      }
      a {
        text-transform: uppercase;
        display: block;
        text-align: center;
        padding: 0.75rem 0.5rem;

        font-size: 11px;
        font-weight: 500;
        line-height: 13px;

        //white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        @include respond-above('md') {
          display: inline-block;
          max-width: 100%;
          margin: 1rem auto;
          padding: 0.75rem 1.5rem;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
        }
        @include respond-below('xs') {
          letter-spacing: 0.5px;
        }
        @include respond-below('xxs') {
          letter-spacing: 0;
          font-size: 10px;
          margin: 0 -10px;
        }
      }
    }
  }
  .section-result {
    background-color: $color_brown_light3;
    position: relative;
    overflow: hidden;
    @include respond-above('md') {
      padding: 2rem;
    }

    //background
    &::before {
      content: '';
      position: absolute;
      opacity: 0;
      z-index: 0;
      width: 300px;
      height: 647px;
      top: 0;
      left: 0;
      background: url('../../images/simulation-bg3.png') no-repeat top left;

      @include respond-above('md') {
        opacity: 0.3;
      }
    }

    .section-title {
      margin-bottom: 1.5rem;
    }

    .result-panel {
      text-align: center;
      @include respond-above('sm') {
        text-align: left;
      }
      h3 {
        font-weight: 700;
        color: $color_white;

        font-size: 25px;
        line-height: 1;
        margin-bottom: 1.5rem;
        @include respond-above('sm') {
          font-size: 30px;
          padding-top: 3rem;
          margin-bottom: 2rem;
        }
        @include respond-above('md') {
          font-size: 37px;
          padding-top: 6rem;
        }
      }
      b,
      p {
        color: $color_black;
        display: block;
        line-height: 22px;
        font-size: 13px;
        margin-bottom: 1rem;
        @include respond-above('sm') {
          font-size: 15px;
          margin-bottom: 2rem;
        }
        @include respond-above('md') {
          max-width: 360px;
        }
      }

      p {
        font-weight: 300;
      }
      b {
        font-weight: 500;
      }
      .controls-container {
        @include respond-below('sm') {
          max-width: 80%;
          margin: 0 auto;
        }
        @include respond-above('lg') {
          max-width: 75%;
        }
        @include respond-above('xl') {
          max-width: 75%;
        }
        .control {
          padding: 0 4px;
        }
      }
      .btn {
        // width: 50%;
        display: block;
        margin-bottom: 1rem;
        i {
          font-weight: bold;
          margin-right: 0.5rem;
        }
        @include respond-above('sm') {
          width: auto;
          padding: 0.5rem 1rem;
          &:not(:last-of-type) {
            margin-right: 2rem;
          }
        }
        @include respond-above('md') {
          padding: 0.75rem 1.5rem;
        }

        &.btn-reset {
          // width: 100%;
          @include respond-above('sm') {
            padding: 0.75rem 1.5rem;
          }
        }
      }
    }
  }

  .modal-dialog {
    .modal-content {
      .modal-body {
        // @include respond-below('xs') {
        //   max-height: 60vh;
        //   overflow: auto;
        // }
        .custom-form {
          margin-top: 25px;
          @include respond-above('md') {
            max-width: 60%;
            margin: 0 auto;
            margin-top: 2rem;
          }
        }
      }
    }
  }

  .select2-container--default {
    .select2-results__group{
      display: none;
    }
  }
  .simulation-attention {
    width: 35%;
  }

  .error-message {
    font-weight: 300;
    margin-top: -10px;

    .error {
      color: red;
      line-height: 1.3;
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}
.link-not-eligible {
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    color: #3b2715;
  }
}

.form-group {
  &.recaptcha {
    position: relative;
    height: 0;
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;
    #recaptcha_is_valid {
      position: absolute;
      width: 0;
      opacity: 0;
      visibility: hidden;
    }
    #recaptcha_is_valid-error {
      width: 100%;
      text-align: center;
      display: block;
    }
  }
}
.g-recaptcha {
  >div[style^="width"] {
    margin: auto;
    height: auto !important;
  }
  &-center {
    margin: 10px auto !important;
    text-align: center;
    width: auto !important;
    height: auto !important;
  }
}

.demandeur-navigate-connection, .demandeur-account-credential-resend {
  width:20%;
}