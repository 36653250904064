@mixin btnSize {
  letter-spacing: 1px;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 0.75rem 1.5rem;
  // font-size: 14px;
}

.btn {
  outline: none;
  box-shadow: none !important;
  font-size: 11px;
  i {
    font-size: 10px;
    &:first-child {
      &::before {
        padding-right: 0.5rem;
      }
    }
  }

  @include respond-above('md') {
    font-size: 12px;
    i {
      font-size: 11px;
    }
  }

  span + i {
    padding-left: 0.5rem;
  }

  // i + span {
  //   padding-right: 0.5rem;
  // }

  &.btn-primary {
    color: $color_brown_dark;
    background-color: $color_primary_bg;
    border-color: $color_primary_bg;
    @include btnSize();

    &:hover,
    &:focus,
    &.hover {
      color: #fff;
      border-color: $color_brown_dark;
      background: $color_brown_dark;
    }
    &.btn:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: #fff;
        background-color: $color_brown_dark !important;
        border-color: $color_brown_dark !important;
      }
    }

  }
  &.btn-outline-primary {
    color: $color_brown_dark;
    border-color: $color_brown_dark;
    border-width: 1px;
    @include btnSize();

    &:hover,
    &:focus,
    &.hover {
      color: $color_white !important;
      border-color: $color_brown_dark;
      background: $color_brown_dark;
    }

    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $color_white !important;
        background-color: $color_brown_dark !important;
        border-color: $color_brown_dark !important;
      }
    }
  }

  &.btn-outline-secondary {
    color: $color_white !important;
    border-color: $color_white !important;
    @include btnSize();

    &:hover,
    &:focus,
    &.hover {
      color: $color_brown_dark !important;
      border-color: $color_white !important;
      background-color: $color_white !important;
    }

    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $color_brown_dark !important;
        border-color: $color_white;
        background-color: $color_white;
      }
    }
  }

  &.btn-outline-yellow {
    background: transparent;
    color: $color_yellow;
    border: 1px solid $color_yellow;
    @include btnSize();

    &:hover,
    &:focus,
    &.hover {
      color: $color_black;
      background-color: $color_yellow !important;
      border-color: $color_yellow !important;
    }
  }
  &.btn-white {
    background: $color_white;
    color: $color_black;
    @include btnSize();

    &:hover,
    &:focus,
    &.hover {
      color: #fff;
      background-color: $color_brown_dark !important;
      border-color: $color_brown_dark !important;
    }
  }

  &.btn-lg {
    padding: 1rem 2rem;
  }
  &.btn-sm {
    padding: 5px 1rem;

    font-size: 11px;
    font-weight: 500;
    i {
      font-weight: 500;
      font-size: 10px;
    }

    @include respond-above('md') {
      font-size: 12px;
    }
  }

  &.btn-icon-big {
    i {
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.show > .btn-outline-secondary.dropdown-toggle {
  color: $color_brown_dark;
  border-color: $color_yellow;
  background: $color_yellow;
}

// Link cover to click item
.link_cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.link-social {
  font-size: 30px;
  &:hover,
  &:focus {
    i {
      &:before {
        color: $color_black;
      }
    }
  }
}

.link {
  &_light {
    color: $color_gray_light_05;
    font-size: 13px;
    font-weight: 300;
    position: relative;
    padding: 1rem;

    &:hover {
      text-decoration: none;
      color: $color_yellow;
    }
    i {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &_underline {
    color: $color_gray_light_05;
    font-size: 13px;
    font-weight: 300;
    position: relative;
    padding: 1rem;

    &:hover {
      text-decoration: none;
      color: $color_yellow;
      &:after {
        background-color: $color_yellow;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0.8rem;
      left: 1rem;
      right: 1rem;
      height: 1px;
      background-color: $color_gray_light_05;
      transform: 0.3s all ease-in-out;
    }
  }
}
