@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animated {
  &.fast {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
  }

  &.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }

  &.slow {
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
  }

  &.slower {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
