form.global-form-style {
  border: 3px solid #f1f1f1;
  /* Full-width inputs */
  input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }

  /* Set a style for all buttons */
  .btn {
    background-color: #28a759;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }

  /* Add a hover effect for buttons */
  .btn:hover {
    background-color: #28a759 !important;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.7;
  }

  .btn:disabled{
    cursor: unset !important;
  }

  /* Center the avatar image inside this container */
  .header-img {
    img {
      max-width: 60%;
    }
    text-align: center;
    margin: 24px 0 12px 0;
  }

  /* Add padding to containers */
  .container {
    padding: 16px;
  }
}
