.components-page {
  section {
    padding: 5rem 0;
    &:nth-child(2n) {
      background-color: $color_white;
    }
    &:nth-child(2n + 1) {
      background-color: $color_gray_light;
    }

    &.section-button {
      background-color: $color_gray_dark;
      color: $color_white;
    }
  }
}
