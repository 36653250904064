footer {
  position: relative;
  z-index: $footer_index;
  // padding: 40px 0 100px;
  padding: 0;
  background-color: $color_footer_bg;

  @include respond-above('md') {
    min-height: 253px;
  }
  // @media (max-width: 991px) {
  //   padding-bottom: 30px;
  // }
  .footer-content {
    @include paddingFooter();
    @include respond-below('sm') {
      padding: 25px;
    }
  }
  .footer_title {
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    white-space: nowrap;
    @include respond-below('lg') {
      white-space: unset;
    }
    @include respond-below('md') {
      font-size: 13px;
    }
  }
  .oph-footer-mask {
    position: absolute;
    right: 0px;
    top: 0;
    background-repeat: no-repeat;
    background-image: url('../../images/footer-mask2.png');
    width: 200px;
    height: 266px;

    @include respond-above('lg') {
      right: 0;
    }
  }

  .footer_links {
    padding: 0;
    font-size: 14px;
    letter-spacing: 1px;
    a {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 1px;
      white-space: nowrap;
      @include respond-below('lg') {
        white-space: unset;
      }
      @include respond-below('md') {
        font-size: 13px;
      }
      @include respond-below('xs') {
        letter-spacing: 0;
        line-height: 20px;
      }
    }
    a:hover {
      color:#f9b233;
    }
  }

  .group-social {
    &__link {
      a {
        font-size: 29px;
        &:not(:first-child) {
          padding-left: 7px;
        }
      }
      &__list{
        @include respond-below('md') {
          justify-content: center!important;
        }
      }
    }

    &__logo {
      @include respond-below('lg') {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .group-links {
    line-height: 25px;
    margin: 10px auto;
    @include respond-below('lg') {
      padding: 15px 0;
      max-width: 170px;
    }
    @include respond-below('sm') {
      max-width: 130px;
    }
  }

  .block_contact {
    background: $color_yellow;
    padding: 25px;
    text-align: left;
    position: relative;

    display: none;
    right: 0;
    bottom: 10%;
    z-index: 3;
    margin-top: 25px;

    @include respond-above('xs') {
      padding: 30px;
    }

    @include respond-above('lg') {
      position: absolute;
      right: 45px;
      bottom: 35%;
      width: 300px;
      &::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -15px;
        right: auto;
        top: 23px;
        bottom: auto;
        border-top: 12px solid transparent;
        border-right: 16px solid #f9b233;
        border-bottom: 12px solid transparent;
      }
    }

    @include respond-above('xl') {
      padding: 40px;
      right: 3%;
    }

    @include respond-below('lg') {
      top: 0;
      width: 100%;
    }

    h4 {
      color: #fff;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 20px;

      font-size: 15px;
      font-weight: 700;
      line-height: 23px;
    }

    .info_agence {
      font-weight: 300;
      line-height: 1.2;
      font-size: 15px;
    }
    .info_other {
      font-weight: 500;
      color: $color_brown;
    }
  }

  .footer-contact {
    padding: 30px 15px;
    @include respond-above('sm') {
      padding: 0;
    }
    @include respond-above('xl') {
      padding: 0 0 0 45px;
    }
  }
}

// Show Block contact only on homepage
.home-page {
  .block_contact {
      display: block !important;
  }
  .footer-contact {
    padding-left: 35px;

    @include respond-below('xl') {
      padding-left: 25px;
    }
    @include respond-below('lg') {
      padding-left: 15px;
    }
  }
  .group-links {
    @include respond-below('lg') {
      margin: 20px auto 0;
    }
  }
}

.group-social__link__list {
  span.link {
    color: #fff;
  }
  span:hover {
    color:#f9b233;
  }
}

.follow_container {
  @include respond-below('lg') {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
}

.group_social_respo {
  @include respond-below('lg') {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.group_face_respo {
  @include respond-below('lg') {
    margin-bottom: 80px;
  }
}

.intertek {
  @include respond-below('lg') {
    display: flex;
    justify-content: center;
  }
}

.iso-logo .group-social__logo{
  max-width: 10rem;
  margin-left: -0.5rem;
}
@media screen and (max-width: 1280px) {
  .group_face_respo {
    margin-bottom: 0;
  }
  .iso-logo .group-social__logo{
    max-width: 10rem;
    margin-left: 0;
  }
}

// /* Motif tatoo */
.oph-footer-motif{
  position: absolute !important;
  right: 0px !important;
  top: 7% !important;
  background-repeat: no-repeat;
  /* background-image: url(/build/images/footer-mask2.fb837eb8.png); */
  width: 13%;
  height: 90%;
}

@media (max-width: 1200px) {
  footer .oph-footer-motif {
    position: absolute !important;
    right: 0px !important;
    top: 1% !important;
    background-repeat: no-repeat;
    width: 200px;
    height: 266px;
  }
}
// /*end - Motif tatoo */