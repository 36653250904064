.form-control:focus {
  box-shadow: none;
}

.custom-form {
  .radio-group {
    margin-bottom: 1rem;
    .radio-container {
      display: flex;
      flex-wrap: wrap;
    }

    .bmd-form-group {
      padding-top: 0;
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
      // fix height
      .radio {
        .bmd-radio {
          margin: -0.5rem;
          .ripple-container {
            display: none;
          }
        }
        span {
          font-size: 15px;
          font-weight: 300;
          line-height: 22px;
          margin: 0 0.25rem;
          color: $color_black;
        }
      }
    }

    // Override radio color
    .radio {
      label {
        input[type="radio"]:checked ~ .bmd-radio {
          &:before {
            background-color: $color_yellow;
          }
          &:after {
            border-color: $color_yellow;
          }
        }
        .bmd-radio {
          &:before {
            background-color: $color_yellow;
          }
        }
      }
    }
  }

  .form-group {
    height: 50px;
    // margin-bottom: 2.5rem;
    .select2-container .selection {
      .select2-selection {
        height: 50px;
      }
      .select2-selection__rendered {
        line-height: 38px;
      }
    }


    // Input text/number
    &.bmd-form-group {
      padding: 1rem 1rem 0.25rem;
      border-radius: 5px;
      border: solid 1px #c0c0c0;
      margin-top: 10px;
      &.disabled {
        background: #ddd;
      }
      .bmd-label-floating {
        top: 1.1rem;
        left: 1rem;
        font-size: 14px;
        font-weight: 300;
        color: $color_gray_light_01;
      }
      input.form-control {
        background-image: none;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        &:focus {
          outline: none;
          border-color: transparent;
          box-shadow: none;
        }
        // color: $color_gray_light_01;
      }

      // Overide floating label when focus
      &.is-filled,
      &.is-focused,
      .bmd-label-static,
      .is-filled,
      .is-focused {
        .bmd-label-floating {
          top: 0.5rem;
          left: 1rem;
        }
      }

      // Overide floating label
      &.is-focused {
        border-color: $color_yellow;
        label {
          color: $color_yellow;
        }
      }
    }

    // Dropdown
    &.dropdown {
      // height: 45px;
      // padding: 0.5rem 1rem;
      border-radius: 5px;
      border: 1px solid silver;
      padding: 0.5rem 0;
      margin-top: 10px;
      .dropdown-toggle {
        display: block;
        padding: 0.5rem 1rem;
        margin: 0;
        text-transform: none;
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        color: $color_gray_light_01;
        &:hover {
          background-color: unset;
        }
        &:after {
          content: "\e903";
          font-family: oph;
          position: absolute;
          right: 1rem;
          transform: translateY(-50%) rotate(90deg);
          color: #000;
          font-weight: 700;
          transition: 0.3s ease-in-out;
          border: none;
          top: 50%;
          width: 10px;
          height: 20px;
          font-size: 10px;
          margin: 0;
          vertical-align: middle;
        }
        .ripple-container {
          display: none;
        }
      }

      // dropdown active
      &.show {
        .dropdown-toggle {
          color: $color_yellow;
          &:after {
            color: $color_yellow;

            transform: translateY(-50%) rotate(-90deg);
          }
        }
      }
      .dropdown-menu {
        width: 100%;
        left: 0 !important;
        top: 45px !important;
        border-radius: 5px;
        padding: 0;
        border: 1px solid $color_gray_light_01;
        // Hide clone btn
        .dropdown-toggle {
          display: none;
        }
      }
      .dropdown-item {
        display: block;
        width: 100%;
        max-width: unset;

        padding: 0 1rem;
        min-height: 45px;
        line-height: 45px;
        &:hover,
        &:focus {
          color: $color_white;
          background-color: $color_yellow;
        }
      }
    }

    &.select2 {
      border: none;
      padding: 0;
      margin-top: 10px;
      .select2-container {
        display: block;
      }

      &.no-search {
        .select2-search {
          display: none;
        }
      }

      // Select Label
      &.select-label {
        .bmd-label-static {
          z-index: 2;
          font-weight: 300;
          font-size: 14px;
          padding-left: 15px;
        }
        .select2-selection__rendered {
          span {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        // Select Label filled
        &.is-filled {
          .bmd-label-static {
            font-size: 10px;
            top: 20%;
          }
          .select2-selection__rendered {
            opacity: 1;
            outline: 0;
            padding-top: 8px;
            padding-left: 15px;
            font-weight: 300;
            &:after {
              top: 0;
            }
          }
        }
      }
      label.error {
        padding: 0.25rem 1rem;
      }
    }

    &.textarea {
      height: 120px;
      padding: 12px 15px 10px;
      .form-control {
        font-size: 14px;
        font-weight: 300;
        background-image: none;
        height: 90px;
      }
    }

    &.has-error {
      margin-bottom: 2.5rem;
    }

    label.error {
      color: red !important;
      display: block;
      width: 100%;
      margin-top: 5px;
      font-weight: 300;
      font-size: 12px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }

  .form-custom-radio {
    margin-top: 5px;
    label {
      label {
          margin-right: 22px;
      }
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
      position: relative;
      cursor: pointer;
      line-height: 1.5;
      display: inline-block;
      padding-left: 28px;
      font-size: 15px;
      font-weight: 300;
    }
    [type="radio"]:checked + label:before {
      border: 2px solid #f9b233 !important;

    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 21px;
      height: 21px;
      border: 2px solid rgba(0,0,0,.54);
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 11px;
      height: 11px;
      background: #f9b233;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  #simulation_particular_conditions, #simulation_town {
    option:first-child {
      display: none;
    }
  }
}
