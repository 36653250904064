.header {
  background-color: $color_white;
  position: relative;
  z-index: $header_index;

  @include respond-below('sm') {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
  }
  // Top Messgage
  .info-message {
    background: $color_yellow;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    height: 40px;
    line-height: 1.5;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1rem;

    display: none;

    @include respond-above('sm') {
      display: block;
      line-height: 40px;

      font-size: 14px;
    }
    .title {
      font-weight: bold;
    }
  }

  // Logo And Nav Btn
  .header-main {
    display: flex;
    // max-width: 100%;
    margin: 0 auto;
    @include paddingHeader();

    .logo {
      .logo-link {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      img {
        max-height: 40px;
        transition: all 0.3s ease-in-out;
        @include respond-above('sm') {
          max-height: 50px;
        }
        @include respond-above('md') {
          max-height: 60px;
        }
        @include respond-above('lg') {
          max-height: 80px;
        }
        @include respond-above('xl') {
          max-height: unset;
        }
      }
    }

    .narbar-container {
      display: flex;
      flex: 1 0 auto;
      flex-direction: row-reverse;
      align-items: center;
      z-index: 3;
    }

    .navbar {
      padding: 0;
      @include respond-above('lg') {
        padding: 1rem;
      }
      .navbar-toggler {
        border: none;
        padding: 5px 0px;
      }
    }

    .nav-item {
      font-weight: 500;
      padding: 0;
      position: static;
      margin: auto;
      text-align: center;
      font-size: 14px;

      @include respond-below('md') {
        display: block;
        width: 100%;
        padding: 0.5rem 0;
        position: relative;
      }

      @include respond-between('md', 'xl') {
        font-size: 12px;
      }

      // Border bottom for mobile
      &:not(:last-of-type) {
        @include respond-below('md') {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 2px;
            background: $color_blue_light;
          }
        }

        .nav-link {
          &:after {
            content: '';
            position: absolute;
            width: 1px;
            top: 50%;
            right: 0;
            height: 10px;
            background: #3b2715;
            transform: translate(0, -50%);
            border: 0;
            @include respond-below('md') {
              display: none;
            }

            @include respond-between('md', 'xl') {
              height: 10px;
            }
          }
        }
      }

      &.dropdown {
        &.show {
          .dropdown-menu {
            box-shadow: none;
            @include respond-below('md') {
              display: block;
            }
            @include respond-above('md') {
              position: absolute;
            }
          }
        }
        &:hover {
          .dropdown-menu {
            opacity: 1;
            top: 100%;
            visibility: visible;
          }
        }
      }
    }
    .nav-link {
      position: relative;
      color: $color_brown_dark;
      font-size: 12px;
      // font-weight: 500;
      font-weight: 500;
      line-height: 16px;
      @include respond-above('md') {
        padding: 0.5rem;
        font-size: 13px;
      }
      @include respond-above('lg') {
        padding: 20px;
        font-size: 14px;
      }

      &:focus,
      &:hover {
        @include respond-above('md') {
          color: $color_yellow;
        }
      }
    }

    .block-search {
      position: relative;
      padding: 0 1rem;
      @include respond-above('md') {
        margin-top: 0.5rem;
        padding: 0 0.5rem;
      }

      i {
        cursor: pointer;
        font-weight: bold;
        color: #3b2715;
        font-size: 20px;
        &:hover {
          color: $color_yellow;
        }
      }
    }

    // Dropdown Menu
    .dropdown-menu {
      display: block;

      border-radius: 0;
      width: 100%;

      opacity: 0;
      visibility: hidden;

      top: 120%;
      padding: 2rem;
      border: 1px solid #f0f0f0;

      z-index: $dropdown_menu_index;
      transition: all 0.3s ease-in-out;
      @include respond-below('md') {
        display: none;
        padding: 0;
        border: none;
        .image_menu {
          display: none;
        }
      }
      @include respond-below('sm') {
        max-width: 80%;
        margin: 0 auto;
      }
      &.show {
        opacity: 1;
        top: 100%;
        visibility: visible;
        display: block;

        @include respond-below('md') {
          position: relative !important;
        }
      }
    }

    .dropdown-item {
      padding: 10px 20px;
      position: relative;
      transition: all 0.3s ease-in-out;

      @include respond-below('md') {
        padding: 5px;
        text-align: center;
      }
      @include respond-below('md') {
        text-align: center;
        display: block;
        max-width: unset;
        min-height: 30px;

        font-size: 13px;
        font-weight: 300;
        line-height: 28px;
      }

      &:after {
        content: '\e903';
        position: absolute;

        font-family: 'oph';
        font-size: 14px;
        font-weight: bold;

        opacity: 0;
        transition: all 0.3s ease-in-out;
        right: -10px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &:focus,
      &:hover {
        color: $color_yellow;
        &:after {
          opacity: 1;
          right: 10px;
        }
      }
    }

    .input_search {
      visibility: hidden;
      position: absolute;
      opacity: 0;
      z-index: 2;
      top: -10px;
      left: -20px;
      width: 100px;
      transition: all 0.3s ease-in-out;

      &.active {
        visibility: visible;
        opacity: 1;
        left: -65px;
      }

      #search_input {
        padding: 0.5rem;
      }
    }

    .form-control {
      border: 1px solid #f0f0f0;
      font-weight: 300;
      background-color: white;
      border-radius: 5px;
      padding: 0.5rem;
      min-width: 200px;
      top: 0;
      &:focus {
        color: $color_yellow;
        border-color: $color_border;
        outline: 0;
      }
    }

    .show > .btn-outline-primary.dropdown-toggle {
      color: #fff;
      background-color: #3b2715;
      border-color: #3b2715;
    }

    // Mobile Menu
    .navbar-collapse {
      background-color: $color_white;
      @include respond-below('md') {
        padding: 9rem 10px 2rem;
        background-color: $color_white;
        text-align: center;
        .form-search {
          position: absolute;

          background-color: #f7f7f7;
          top: 60px;
          left: 0;
          width: 100%;
          text-align: left;
          .block-search {
            padding: 1rem 2rem;
          }
          .input_search {
            &.active {
              left: 4rem;
              min-width: 250px;
              top: 0.5rem;
              margin-bottom: 0;
            }
          }
        }
      }

      &.collapse,
      &.collapsing {
        display: block;
        transition: all 0.3s ease-in-out;
        z-index: $header_index;
        @include respond-below('md') {
          position: fixed;
          width: 100%;
          height: 100%;
          // z-index: $header_index;
          top: 0;
          bottom: 0;
          right: 0;
          left: 100%;
          overflow: auto;
        }

        @include respond-above('md') {
          position: relative;
        }
      }

      &.collapsing {
        height: 100%;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
      }

      &.show {
        left: 0;
      }

      .logo {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 10px;
        @include respond-above('md') {
          display: none;
        }
        // a {
        //   padding: 0.5rem;
        // }
        // img {
        //   height: 30px;
        // }
      }

      .close_menu,
      .close_menu2 {
        cursor: pointer;
        display: inline-block;
        font-size: 25px;
        padding: 0;
        line-height: 30px;
        position: absolute;
        top: 15px;
        right: 10px;
        &:hover {
          color: $color_yellow;
        }
        .ic-close {
          color: $color_black;
          font-weight: bold;
        }

        // Hide Close btn on desktop
        @include respond-above('md') {
          display: none;
        }
      }

      // Btn Login

      .btn-control {
        font-size: 11px;
        font-weight: bold;
        i {
          font-weight: bold;
          font-size: 1rem;
        }
        span {
          display: inline-block;
          vertical-align: top;
          // padding-right: 0.5rem;
          margin-right: 0.5rem;
        }
        @include respond-below('md') {
          margin-top: 2rem;
        }
        @include respond-above('md') {
          margin-left: 2rem;
          margin-top: 0.5rem;
          padding: 5px 10px;
        }
      }
    }
  }

  .fixed-header {
    &.hide_header {
      position: absolute;
      top: -100%;

    }
  }

  @include respond-below('sm') {
    &.header-alert + #main-container {
      padding-top: 60px;
    }
  }

  //Banniere defilant
  .fluid-banner-info {
    background: #f9b233;
    height: 40px;
    font-weight: 300;
  }
  .banner-align-vert {
    margin: 10px auto;
  }
  /* Texte défilant */
  .messagedefilant {
    display: block;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    /*max-width: 640px;*/
    height: 40px;
  }

  .messagedefilant div {
    position: absolute;
    min-width: 100%; /* au minimum la largeur du conteneur */
  }

  .messagedefilant div span,
  .messagedefilant div:after {
    position: relative;
    display: inline-block;
    /*font-size: 2rem;*/
    white-space: nowrap;
    top:0;
  }

  .messagedefilant div span {
    animation: defilement 15s infinite linear;
    /*background: #cde;*/
  }

  .messagedefilant div:after {
    position: absolute;
    top:0; left:0;
    content:attr(data-text);
    animation: defilement2 15s infinite linear;
    /*background: #edc;*/
  }

  @keyframes defilement {
    0% { margin-left: 0; }
    100% { margin-left: -100%; }
  }

  @keyframes defilement2 {
    0% { margin-left: 100%; }
    100% { margin-left: 0%; }
  }
  @media (max-width: 770px) {
    .fluid-banner-info {
      height: 60px;
    }
    .banner-align-vert {
      margin: 0 auto;
    }
  }
}
