.oph-tabs {
  &--style-01 {
    background-color: $color_gray_light;
    white-space: nowrap;
    overflow-x: auto;
    padding: 0;
    @include respond-above('md') {
      padding: 1rem;
    }
    .oph-tabs {
      &__items {
        flex-wrap: nowrap;
        .nav-item {
          //    width: 33.33%;
        }
        .nav-link {
          color: $color_brown_light;

          padding: 5px 2rem 5px 0;
          text-transform: none;
          font-weight: 700;
          font-size: 30px;
          @media (max-width: 991px) {
            font-size: 20px;
            color: $color_brown_light;
            padding-right: 15px;
          }
          @media (max-width: 767px) {
            font-size: 18px;
            padding: 0 2rem 0 0;
          }
          &:hover {
            @media only screen and (min-width: 1025px) {
              color: $color_brown;
            }
          }
          &.active {
            color: $color_brown;
            &:after {
              opacity: 1;
              visibility: visible;
              z-index: 2;
            }
          }
          &:after {
            content: '';
            bottom: -6px;
            left: 50%;
            width: 0px;
            height: 0px;
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }

  &--style-02 {
    white-space: nowrap;
    overflow-x: auto;
    padding: 0;
    .oph-tabs {
      &__items {
        flex-wrap: nowrap;
        padding: 0 70px;
        .nav-link {
          color: $color_brown;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 15px;
          padding: 5px 15px;
          &:hover {
            @media only screen and (min-width: 1025px) {
              color: $color_yellow;
            }
          }
          &.active {
            color: $color_yellow;
            &:after {
              opacity: 1;
              visibility: visible;
              z-index: 2;
            }
          }
          &:after {
            content: '';
            bottom: -6px;
            left: 50%;
            width: 0px;
            height: 0px;
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

.slider-for {
  @media (max-width: 766px) {
    &.has-slider {
      opacity: 0;
      visibility: hidden;
    }
    &.slick-initialized {
      opacity: 1;
      visibility: visible;
    }
  }
}

.fix-height-swipper-mobile {
  @media (max-width: 766px) {
    .slick-list {
      height: auto !important;
    }
    .tab-pane {
      &.slick-active {
        left: 0 !important;
      }
      &:not(.slick-active) {
        position: absolute !important;
      }
    }
  }
}
