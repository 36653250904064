.table {
  thead {
    th {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 300;
      color: $color_blue_light;
      border-bottom: solid 1px $color_blue_light;
      vertical-align: middle;
      font-size: 13px;
      padding: 10px 5px 5px;

      @include respond-above('md') {
        font-size: 15px;
        padding: 10px 5px 5px;
      }
    }
  }
  tbody {
    td {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 300;
      color: $color_black;
      vertical-align: middle;
      font-size: 13px;
      padding: 10px 5px 5px;
      border-top: none;
      @include respond-above('md') {
        font-size: 15px;
        padding: 10px 5px 5px;
      }
    }
  }

  .btn {
    font-size: 12px;
    font-weight: 500;
    padding: 9px 20px;
    float: right;
  }
}
