@font-face {
   font-family: "oph";
   src: url("/build/fonts/oph/oph.eot?oqy7i3");
   src: url("/build/fonts/oph/oph.eot?oqy7i3#iefix")
         format("embedded-opentype"),
      url("/build/fonts/oph/oph.ttf?oqy7i3") format("truetype"),
      url("/build/fonts/oph/oph.woff?oqy7i3") format("woff"),
      url("/build/fonts/oph/oph.svg?oqy7i3#oph") format("svg");
   font-weight: normal;
   font-style: normal;
   font-display: block;
}
@font-face {
   font-family: "password";
   src: url("/build/fonts/password/password.eot");
   src: url("/build/fonts/password/password.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/password/password.woff2") format("woff2"),
      url("/build/fonts/password/password.woff") format("woff"),
      url("/build/fonts/password/password.ttf") format("truetype"),
      url("/build/fonts/password/password.svg#Ubuntu-LightItalic")
         format("svg");
   font-weight: 400;
   font-style: italic;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-Regular.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-Regular.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-Regular.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-Regular.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-Regular.svg#Ubuntu-Regular")
         format("svg");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-BoldItalic.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-BoldItalic.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-BoldItalic.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-BoldItalic.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-BoldItalic.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-BoldItalic.svg#Ubuntu-BoldItalic")
         format("svg");
   font-weight: bold;
   font-style: italic;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-LightItalic.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-LightItalic.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-LightItalic.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-LightItalic.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-LightItalic.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-LightItalic.svg#Ubuntu-LightItalic")
         format("svg");
   font-weight: 300;
   font-style: italic;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-Medium.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-Medium.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-Medium.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-Medium.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-Medium.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-Medium.svg#Ubuntu-Medium")
         format("svg");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-Bold.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-Bold.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-Bold.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-Bold.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-Bold.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-Bold.svg#Ubuntu-Bold") format("svg");
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-Light.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-Light.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-Light.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-Light.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-Light.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-Light.svg#Ubuntu-Light") format("svg");
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-Italic.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-Italic.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-Italic.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-Italic.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-Italic.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-Italic.svg#Ubuntu-Italic")
         format("svg");
   font-weight: normal;
   font-style: italic;
}

@font-face {
   font-family: "Ubuntu";
   src: url("/build/fonts/ubuntu/Ubuntu-MediumItalic.eot");
   src: url("/build/fonts/ubuntu/Ubuntu-MediumItalic.eot?#iefix")
         format("embedded-opentype"),
      url("/build/fonts/ubuntu/Ubuntu-MediumItalic.woff2") format("woff2"),
      url("/build/fonts/ubuntu/Ubuntu-MediumItalic.woff") format("woff"),
      url("/build/fonts/ubuntu/Ubuntu-MediumItalic.ttf") format("truetype"),
      url("/build/fonts/ubuntu/Ubuntu-MediumItalic.svg#Ubuntu-MediumItalic")
         format("svg");
   font-weight: 500;
   font-style: italic;
}
