@import '../variables';
@import '../mixins';

.lotissements-page {
  &.show-detail {
    .section-header {
      display: none;
    }
    .section-map {
      display: none;
    }
  }
  .section-header {
    @include respond-below('md') {
      padding: 0;
    }
    .section-title {
      padding: 0;
      @include respond-below('md') {
        padding: 25px;
      }
    }
    .section-filter {
      padding: 0;
      @include respond-below('md') {
        background-color: white;
        padding: 10px 25px;
        margin-top: 0;
      }
      .bmd-form-group {
        @include respond-below('md') {
          display: block;
          width: 100%;
          padding: 0;
        }
      }
      .select2-container {
        @include respond-below('md') {
          width: 100% !important;
        }
        .select2-selection__rendered {
          min-width: 200px;
        }
      }
    }
    hr {
      @include respond-below('md') {
        display: none;
      }
    }
  }
  .section-map {
    position: relative;
    z-index: 0;
    .map-leaflet {
      height: 300px;
      display: block;
      position: relative;
      font-family: 'Ubuntu', sans-serif;

      @include respond-above('xs') {
        height: 400px;
      }
      // Tablet above
      @include respond-above('sm') {
        height: 500px;
      }
      @include respond-above('md') {
        height: 600px;
      }
    }
    .map-control-panel {
      z-index: $map_control_index;
      position: relative;

      // Tablet above
      @include respond-above('md') {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
      }
      .nicescroll-cursors {
        background-color: $color_gray_light_01 !important;
      }
    }

    .lotissements__list {
      // position: relative;
      // height: 400px;
      background-color: $color_white;
      @include respond-above('md') {
        height: 100%;
      }
    }
    .lotissements__item {
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;

      padding: 2rem;
      transition: 300ms ease-in-out;
      max-width: 100%;

      @include respond-above('sm') {
        padding: 2rem 3rem 2rem;
      }
      @include respond-above('md') {
        padding: 2rem 2rem 2rem;
        max-width: 400px;
      }

      @include respond-above('lg') {
        padding: 2rem 3rem 2rem;
      }
      &:after {
        content: '';
        position: absolute;
        left: 2rem;
        right: 2rem;
        bottom: 0;
        height: 1px;
        background-color: $color_border;
      }

      .information {
        padding-right: 4rem;
        width: 100%;

        h2 {
          font-weight: bold;
        }
        h2,
        p {
          color: $color_brown_dark;
          letter-spacing: 1px;

          font-size: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .btn-detail {
        color: $color_brown_dark;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translate(200%, -50%);
        z-index: 4;
        @include respond-above('sm') {
          right: 2rem;
        }
        i {
          font-size: 40px;
          font-weight: bold;
        }
        &.active,
        &:hover {
          color: $color_white;
        }
      }

      &.active {
        background-color: $color_yellow;
        z-index: 2;
        &:after {
          display: none;
        }
        .btn-detail {
          transform: translate(0%, -50%);
        }
      }
      &:hover {
        background-color: $color_yellow;
        &:after {
          display: none;
        }
        .information {
          h2,
          p {
            color: $color_white;
          }
        }
      }
    }

    .mobile-loadmore {
      display: none;

      &.show {
        display: flex;
        justify-content: center;
      }
    }
  }
  .section-detail {
    display: none;
    background-color: $color_gray_light;
    z-index: $map_detail_index;
    transition: 300ms ease-in-out;

    position: relative;
    // top: 5rem;

    @include respond-above('sm') {
      position: relative;
      top: unset;
      padding: 3rem 3rem 2rem;
    }
    @include respond-above('md') {
      padding: 3rem 2rem 2rem;
    }

    @include respond-above('lg') {
      padding: 5rem 3rem 2rem;
    }
    &.show {
      display: block;
    }
    .btn-close {
      position: absolute;
      top: 2rem;
      right: 1rem;
      z-index: 2;
      @include respond-below('xs') {
        position: fixed;
        top: 5rem;
      }
      @include respond-above('md') {
        display: none;
      }
      i {
        font-size: 25px;
        font-weight: bold;
        @include respond-above('md') {
          font-size: 40px;
        }
      }
    }
    .content {
      position: relative;
      // max-height: 70vh;
      // overflow: auto;
      padding: 2rem;
      @include respond-below('sm') {
        max-height: 100%;
        overflow: auto;
      }
      @include respond-above('lg') {
        padding: 5rem 8rem;
      }
      @include respond-above('xl') {
        padding: 5rem 10rem;
      }
      @include respond-above('xxl') {
        padding: 5rem 15rem;
      }
    }
    .place-title {
      // font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 2rem;
      color: $color_gray_dark;

      font-size: 20px;
      font-weight: 300;
      @include respond-above('md') {
        font-size: 50px;
      }
    }
    .place-description {
      font-size: 14px;
      font-style: italic;
      color: $color_blue;
      margin-bottom: 1rem;
      font-weight: 300;
      text-align: justify;
      @include respond-above('md') {
        font-size: 18px;

        max-width: 50%;
        padding-right: 7rem;
      }
    }
    .place-content {
      font-style: normal;
      color: $color_black;
      font-size: 13px;
      @include respond-above('md') {
        font-size: 15px;
      }
      p {
        margin-bottom: 1rem;
        font-size: 15px;
        font-weight: 300;
        line-height: 30px;
        text-align: justify;

        @include respond-above('xxl') {
          font-size: 16px;
        }
      }
      img {
        width: 100%;
      }
    }
  }
}
