@import '../variables';
@import '../mixins';

.footer-mask {
   display: none;
}

textarea {
   resize: none;
}

.mw-100 {
   max-width: 100%;
}

.fw-500 {
   font-weight: 500;
}

.fw-300 {
   font-weight: 300;
}

.postuler,
.demandeur {
   .select2-results__option {
      font-weight: 300;
      font-size: 13px;
   }
   .select2-container {
      &--default {
         .select2-search--dropdown {
            padding: 10px 5px 5px;
            .select2-search {
               &__field {
                  outline: 0;
                  border-radius: 5px;
                  padding: 8px;
               }
            }
         }
      }
   }
}

.cus-col-26 {
   @media (min-width: 768px) {
      flex: 0 0 26%;
      max-width: 26%;
   }
}
.cus-col-37 {
   @media (min-width: 768px) {
      flex: 0 0 37%;
      max-width: 37%;
   }
}

.form-group-upload {
   &__inner {
      margin-top: 20px;
   }
}

.form-upload-single-link {
   .form-group {
      height: auto;
      margin-bottom: 0 !important;
   }
   .error-message {
      margin-top: 5px !important;
   }
   &__wrapper {
      position: relative;
      overflow: hidden;
      @media (max-width: 414px) {
         width: 280px;
      }
   }
   &__file {
      position: absolute;
      top: -17px;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      font-size: 100px;
      cursor: pointer;
      input[type="file"] {
         cursor: pointer;
      }
   }
   &__button {
      padding: 12px 10px 12px 25px;
      background: #f7f7f7;
      border-radius: 30px;
      border: 1px solid #eee;
      &.uploaded {
         .icon-download-document {
            &:before {
               content: "\e90c";
            }
         }
         .text {
            color: $color_primary_bg;
         }
      }
   }
   &__note {
      font-size: 14px;
      font-weight: 300;
      color: $color-gray;
      margin-top: 5px;
   }
   &__label {
      font-size: 14px;
      margin-bottom: 10px;
   }

   &__label,
   .text {
      font-weight: 300;
   }
   .icon {
      position: relative;
      top: -1px;
      font-weight: 600;
   }
   .text {
      font-size: 13px;
      margin-left: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $color-gray;
   }
}
.detect-ie {
   .primary-form {
      &.custom-form {
         .form-group {
            &.select2.select-label {
               .select2-container {
                  .selection {
                     .select2-selection {
                        &__arrow {
                           display: none;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
.primary-form {
   // padding-top: 50px;
   // @media (max-width: 767px) {
   //     padding-top: 15px;
   // }
   &.custom-form {
      .form-group {
         //background: #fff;
         margin-bottom: 15px;
         &.select2.select-label {
            .select2-container {
               .selection .select2-selection {
                  outline: 0;
               }
               &.select2-container--open {
                  .selection {
                     .select2-selection {
                        border-color: $color_primary_bg !important;
                        &__rendered {
                           padding-top: 13px;
                           padding-left: 15px;
                           color: #000;
                           font-weight: 300;
                           // color: silver;
                           // font-weight: 400;
                           &:after {
                              top: 0;
                           }
                        }
                        &__arrow {
                           b {
                              &:after {
                                 color: #f9b233;
                                 -webkit-transform: rotate(-90deg);
                                 transform: rotate(-90deg);
                              }
                           }
                        }
                     }
                  }
               }
            }
            .select2-container {
               .selection {
                  .select2-selection {
                     &__arrow {
                        display: block;
                        b {
                           border-color: transparent;
                           margin: 0;
                           border: none;
                           &:after {
                              content: "\e903";
                              font-family: oph, serif;
                              position: absolute;
                              right: 4px;
                              top: 1px;
                              transform: rotate(90deg);
                              color: #000;
                              font-weight: 700;
                              transition: 0.3s ease-in-out;
                              font-size: 10px;
                           }
                        }
                     }
                     &__rendered {
                        opacity: 0;
                     }
                  }
               }
            }
            &.select-filled {
               .bmd-label-static {
                  font-size: 10px;
                  top: 20%;
               }
               .select2-container {
                  .selection {
                     .select2-selection {
                        &__rendered {
                           opacity: 1;
                           outline: 0;
                           padding-top: 8px;
                           padding-left: 15px;
                           // color: #000;
                           // font-weight: 300;
                           color: $color_black;
                           font-weight: 300;
                           &:after {
                              top: 0;
                           }
                        }
                        &__arrow {
                           display: none;
                        }
                     }
                  }
               }
            }
            .bmd-label-static {
               z-index: 2;
               font-weight: 300;
               font-size: 14px;
               padding-left: 15px;
               top: 1rem;
            }
         }
         &.textarea {
            height: 120px;
            padding: 12px 15px 10px;
            .form-control {
               font-size: 14px;
               font-weight: 300;
               background-image: none;
               height: 90px;
               &:focus{
                  box-shadow: none;
               }
            }
         }
         &.bmd-form-group {
            &.is-focused,
            &.is-filled {
               .bmd-label-floating {
                  font-size: 10px;
                  top: 30%;
               }
               .form-control {
                  padding-top: 5px;
                  color: #000;
               }
            }
            .bmd-label-floating {
               font-size: 14px;
               font-weight: 300;
               top: 50%;
               transform: translateY(-50%);
            }
         }
      }
      .radio-group {
         margin-bottom: 10px;
         .label-title {
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 15px;
         }
         .radio {
            label {
               .bmd-radio {
                  &:after {
                     border: 1px solid $color-gray;
                  }
               }
            }
         }
      }
      .error-message {
         font-weight: 300;
         margin-top: -10px;
         // line-height: 1.3;
         .error {
            color: red;
            line-height: 1.3;
            font-size: 13px;
            margin-bottom: 0;
         }
      }
   }
   ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #c0c0c0;
      font-weight: 300;
      font-size: 14px;
   }
   ::-moz-placeholder {
      /* Firefox 19+ */
      color: #c0c0c0;
      font-weight: 300;
      font-size: 14px;
   }
   :-ms-input-placeholder {
      /* IE 10+ */
      color: #c0c0c0;
      font-weight: 300;
      font-size: 14px;
   }
   :-moz-placeholder {
      /* Firefox 18- */
      color: #c0c0c0;
      font-weight: 300;
      font-size: 14px;
   }
   ::-webkit-file-upload-button {
      cursor: pointer;
   }
   .form-upload-single-link {
      + .form-upload-single-link {
         margin-left: 30px;
      }
      @media (max-width: 767px) {
         margin-bottom: 10px;
      }
   }
   .form-group-upload {
      margin-top: 70px;
      @media (max-width: 767px) {
         margin-top: 15px;
      }
   }
   .submit-form {
      margin-top: 100px;
      margin-bottom: 40px;
      @media (max-width: 767px) {
         margin-top: 40px;
         margin-bottom: 30px;
      }
      .btn {
         font-size: 12px;
         padding: 12px 15px;
         min-width: 265px;
         @media (max-width: 767px) {
            min-width: auto;
            width: 90%;
         }
      }
   }
}

.header {
   .header-main {
      #my-espace-dropdown {
         +.dropdown-menu {
            padding: 20px 15px;
            min-width: 300px;
            right: 0;
            left: auto;

            @include respond-below('md') {
               box-shadow: none;
               max-width: 100%;

               .btn {
                  display: block;
               }
            }
         }
      }
   }
}
